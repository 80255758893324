import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Priscription from '../images/media/prescription.png';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import img1 from '../images/icons/eye.png';
import img2 from '../images/icons/treatment.png';
import { callingAPI } from '../config';
import { Link } from 'react-router-dom';
import tivkmark from '../images/icons/tivkmark.png';
import NoMedicineFound from '../Common/noMedicineFound';
import Loader from '../Common/loader';
import Editicon from '../images/icons/edit-bg.png';
import Print from '../images/icons/printer_icon.png';
import Sms from '../images/icons/chat.png';
import sendEmail from '../images/icons/sendEmail.png';
import { useParams } from 'react-router-dom';
import { interactionSettingsStore, intersectSpans } from '@fullcalendar/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var moment = require('moment');

function PatientPriscription() {
	const navigate = useNavigate();

	const [patientPrescriptionList, setPatientPrescriptionList] = useState([]);
	const [patientPrescriptionList1, setPatientPrescriptionList1] = useState([]);
	const [prescriptionImage, setPrescriptionImage] = useState([]);
	const [flag, setFlag] = useState(0);
	const [imageArrs, setImageArrs] = useState('');
	const [orderId, setOrderId] = useState('');
	const [dispensed, setDispensed] = useState('');
	const [write, setWrite] = useState('write');
	const [procedureCheckIds, setprocedureCheckIds] = useState([]);
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	const checkedTaxArrCopy = []
	let { rxPrint } = useParams();
	const getPatientImagePrescription = () => {
		window.sessionStorage.removeItem('fromSaveAndPrint');
		window.sessionStorage.setItem('drVisitIdOfMedicine', "")
		const apiJson = {
			functionName: 'prescriptionList',
			scheduledTimeSlotId: '',
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: "",
			hospitalId: localStorage.getItem('hospitalId'),
			visitId: '',
		};
		callingAPI('medicinePrescription/prescriptionList', apiJson).then((data) => {
			if (data.data.success === '1') {
				setPrescriptionImage(data.data.result.prescriptionImageData);
				setPatientPrescriptionList(data.data.result.prescriptionMedicineData);
				var array = []
				// for (let i=0;i<data.data.result.prescriptionMedicineData[0].prescriptionData[i].length;i++){
				// 	array=data.data.result.prescriptionMedicineData[0].prescriptionData[i]
				// }
				if (data.data.result.prescriptionMedicineData.length) {
					setOrderId(data.data.result.prescriptionMedicineData[0].prescriptionData[0].prescriptionDetails[0].doctorVisitMedicineId)

				}
				setFlag(1);
				setImageArrs(
					process.env.REACT_APP_S3_BUCKET_URL +
					process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
					process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH
				);
			}
		});
	};
	const checkedMedicineChange = (doctorVisitMedicineId, e) => {
		var completedArray = [...procedureCheckIds];
		if (typeof completedArray === 'undefined') {
			completedArray = [];
		}
		var treatmentArrayIndex = completedArray.indexOf(doctorVisitMedicineId);
		if (treatmentArrayIndex >= 0) {
			completedArray.splice(treatmentArrayIndex, 1);
		} else {
			completedArray.push(doctorVisitMedicineId);
		}
		setprocedureCheckIds(completedArray)
		if (completedArray.length > 0) {
			document.getElementById("invoice").classList.remove("test")
		} else {
			document.getElementById("invoice").classList.add("test")
		}
	};
	const mouseEnterHoverFunction = (e, index) => {
		var el = document.getElementById('pu_generalinfo' + index);
		el.style.display = 'block';
	};
	const mouseEnterLeaveFunction = (e, index) => {
		var el = document.getElementById('pu_generalinfo' + index);
		el.style.display = 'none';
	};

	useEffect(() => {
		// if (rxPrint === "1") {
		if (window.sessionStorage.getItem('fromSaveAndPrint') == 1 && window.sessionStorage.getItem('typeOrWrite') == "type") {
			var a = `${process.env.PUBLIC_URL}/patients/priscriptionPrint/${window.sessionStorage.getItem('doctorVisitIdOfPrint') + '/' + localStorage.getItem('hospitalId') + '/' + window.sessionStorage.getItem('patientDoctorId') + '/' + "p" + '/' + "t"}`
			window.open(a, "_blank")
		}
		else if (window.sessionStorage.getItem('fromSaveAndPrint') == 1 && window.sessionStorage.getItem('typeOrWrite') == "write") {
			var a = `${process.env.PUBLIC_URL}/patients/priscriptionPrint/${window.sessionStorage.getItem('doctorVisitIdOfPrint') + '/' + localStorage.getItem('hospitalId') + '/' + window.sessionStorage.getItem('patientDoctorId')}`
			window.open(a, "_blank")
		}
		else if (window.sessionStorage.getItem('fromSaveAndPrint') == 0) {
			window.sessionStorage.setItem('drVisitIdOfMedicine', "")
		}
		getPatientImagePrescription();
	}, []);
	const printId = (data, date, typeOfPrint, typeOrWrite, drMedicineId) => {
		moment(date).format('YYYY-MM-DD')
		if (typeOrWrite == "write") {
			var a = `${process.env.PUBLIC_URL}/patients/priscriptionPrint/${data + '/' + localStorage.getItem('hospitalId') + '/' + window.sessionStorage.getItem('patientDoctorId')}`
			window.open(a, "_blank")
		}
		else {
			var a = `${process.env.PUBLIC_URL}/patients/priscriptionPrint/${data + '/' + localStorage.getItem('hospitalId') + '/' + window.sessionStorage.getItem('patientDoctorId') + '/' + typeOfPrint + '/' + typeOrWrite}`
			window.open(a, "_blank")
		}
	};
	const sendSms = (id) => {
		const postData = {
			"userId": window.sessionStorage.getItem('userId'),
			"orderId": orderId,
			"doctorVisitId": id,
			"mode": "Prescription",
			"hospitalId": localStorage.getItem('hospitalId'),
			"siteId": window.sessionStorage.getItem("siteId")
		};
		callingAPI('commonUserFunctions/sendPrescriptionSMSToUser', postData).then((data) => {
			if (data.data.success === '1') {
				const PostData = {
					functionName: "createAppointmentMail",
					siteId: window.sessionStorage.getItem("siteId"),
					smsObj: {
						mobileNumber: data.data.result.smsObj.mobileNumber,
						message: data.data.result.smsObj.message,
						userId: data.data.result.smsObj.userId,
						siteId: data.data.result.smsObj.siteId,
						hospitalId: data.data.result.smsObj.hospitalId,
						smsType: data.data.result.smsObj.smsType,
						smsTemplateId: data.data.result.smsObj.smsTemplateId
					},
				};
				callingAPI("commonUserFunctions/createAppointmentMail", PostData).then((response) => {
					if (response.data.success === "0") {
						toast.error(response.data.errorMessage);
					}
					else if (response.data.success === "1") {
						toast.success(" Sms Send Successfully")
						window.location.href = '/patients/patientPriscription';
					}
				})
					.catch();
			}
			else {

			}
		})
			.catch();
	};
	const sendEmails = (id, date) => {
		moment(date).format('YYYY-MM-DD')
		const postData = {
			"prescriptionDate": moment(date).format('YYYY-MM-DD'),
			"medicineId": "",
			"doctorVisitId": id,
			"userId": window.sessionStorage.getItem('userId'),
			"orderId": orderId,
			"mode": "Prescription",
			"hospitalId": localStorage.getItem('hospitalId'),
			"siteId": window.sessionStorage.getItem("siteId")
		};
		callingAPI('commonUserFunctions/sendPrescriptionEmailToUser', postData).then((data) => {
			if (data.data.success === '1') {
				const PostData = {
					jsonMailOptions: {
						to: data.data.result.jsonMailOptions.to,
						AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
						SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
						subject: data.data.result.jsonMailOptions.subject,
						html: data.data.result.jsonMailOptions.html,
						bccEmailRequired: data.data.result.jsonMailOptions.bccEmailRequired,
						ccEmailRequired: data.data.result.jsonMailOptions.ccEmailRequired
					},
				};
				callingAPI("commonUserFunctions/createAppointmentMail", PostData).then((response) => {
					if (response.data.success === "0") {
						toast.error("Something went wrong")
					}
					else if (response.data.success === "1") {
						toast.success("Email send successfully")
						window.location.href = '/patients/patientPriscription';
					}
				})
					.catch();
			}
		})
			.catch();
	}
	const imageClick = (data) => {
		document.getElementById('priscriptionThumbnailPopup').src = data;
	};
	const drop = (divid, i) => {
		var elementDisplay = document.getElementById(divid + i);
		elementDisplay.style.display = (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divid + i,
			count: (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 0 : 1
		})
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divid + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}

	const drop1 = (divid, i) => {
		var elementDisplay = document.getElementById(divid + i);
		elementDisplay.style.display = (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divid + i,
			count: (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 0 : 1
		})
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divid + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	const invoiceClick = () => {
		document.getElementById('treatmentCompleteTick').click();
	}
	const editPrescription = (data) => {
		console.log("editPrescription.....", data)
		navigate('/patients/patientPriscriptionAdd', { state: data })
	}
	const editPrescriptiontyping = (data) => {
		console.log("editPrescriptiontyping.....", data)
		navigate('/patients/patientPriscriptionAdd', { state: data })
	}
	const addPatientPriscription = (data) => {
		navigate('/patients/patientPriscriptionAdd', { state: data })
	}
	const editPatientPriscription = (data) => {
		navigate('/patients/patientInvoiceAdd', { state: data })
	}

	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div className="contentWraper contentWraperScroll presptn ">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">
													PRESCRIPTIONS
												</span>

												<span class="treatmentPlanBtn pl_blue_btn">
													<nav onClick={() => addPatientPriscription({ data: '' })}
													>
														+ Add
													</nav>

												</span>

												<span className="treatmentPlanBtn pl_blue_btn test " id="invoice" onClick={() => invoiceClick()} >
													<nav onClick={() => editPatientPriscription({ prescriptionIds: procedureCheckIds, hospitalId: localStorage.getItem('hospitalId'), memberId: window.sessionStorage.getItem('memberId'), isFlag: "" })}
														style={{ display: 'none' }}
														id={'treatmentCompleteTick'}>
													</nav>
													Create invoice for selected medicine(s)
												</span>
											</div>

											{flag == 0 ? (
												<div></div>
											) : prescriptionImage.length || patientPrescriptionList.length ? (
												<div>
													{/* ========== PRISCRIPTION IMAGE BEGINS ========= */}

													{prescriptionImage.map((listObj, id) => (
														<div class="treatmentPlanList1" style={{ height: 'auto' }}>
															<div class="treatmentTable planActive prescriptionTableIMages">
																{listObj.visit_date}
																<table>
																	<tbody>
																		<tr class="groupBrdr">
																			<td colspan="11" class="col-md-10">
																				<div class="treatmentTable prescriptionTable">
																					<div
																						class="drawTumbVisit"
																						style={{ width: '100%' }}>
																						<a
																							href=""
																							data-toggle="modal"
																							data-target="#myModalCanvas"
																							title="Click to View"
																							style={{
																								border: '0!important',
																							}}>
																							{listObj.prescriptionDetails.map(
																								(imgDta, id) => (
																									<div
																										class="drawTumbItemVisit"
																										id="drawTumbItemVisit0">
																										<div class="popup-link">
																											<a href="#popup1" onClick={() => {
																												imageClick(
																													imageArrs +'/'+
																													imgDta.prescriptionImageName
																												);
																											}}>
																												<img
																													className="priscriptionThumbnail"
																													src={
																														imageArrs+'/' + imgDta.prescriptionImageName
																													}
																												/>
																											</a>
																										</div>
																									</div>
																								)
																							)}
																						</a>
																					</div>
																				</div>
																			</td>

																			<td class="col-md-2">
																				<div class="dentalProNote">
																					<span class="elipsis" title="">
																						&nbsp;
																					</span>

																					<label class="dropdown">
																						<div class="dd-button" onClick={() => {
																							drop1("dropdownID1", listObj.visitId)
																						}} />

																						<input
																							type="checkbox"
																							class="dd-input"
																							id="test"
																						/>


																						<ul class="dd-menu" id={"dropdownID1" + listObj.visitId}>
																							{moment(new Date()).format(
																								'DD-MMM-YYYY'
																							) == listObj.visit_date && listObj.doctorId ? (
																								<div>
																									<nav onClick={() => editPrescription({
																										data:
																											listObj.visitId,
																										type:
																											'write',
																									})}
																									>
																										<img src={Editicon} style={{ width: '15px', marginRight: '5px' }} />Edit
																									</nav>


																									<a onClick={(e) =>
																										printId(listObj.visitId, listObj.visit_date, "image", "write")}>
																										<li>
																											<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print{' '}
																										</li>
																									</a>

																									{/* <li>Send SMS</li> */}
																								</div>
																							) : <div>
																								<a onClick={(e) =>
																									printId(listObj.visitId, listObj.visit_date, "image", "write")}>
																									<li>
																										<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print{' '}
																									</li>
																								</a>
																							</div>}
																						</ul>
																					</label>
																				</div>
																			</td>
																		</tr>

																		<tr class="dentalProPlaned">
																			<input
																				type="hidden"
																				name="hidTreatmentIds10"
																				id="hidTreatmentIds10"
																				value=""
																			/>
																			<td colspan="12">
																				<div class="plannedBy">
																					Prescribed by{' '}
																					<span> {listObj.PrescribedBy ? 'Dr:' + listObj.PrescribedBy : ''}</span>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													))}

													{/* ================ PRISCRIPTION IMAGE ENDS HERE  ================ */}
													{patientPrescriptionList.map((listObj, i) => (
														<div class="treatmentPlanList1" style={{ height: 'auto' }}>
															<div class="treatmentPlanDate">{listObj.visit_date}</div>
															{/* img */}

															<div class="treatmentTable planActive">
																<table>
																	<thead>
																		<tr>
																			<th class="col-md-3">
																				<div class="dentalProName">
																					Medicine
																				</div>
																			</th>
																			<th class="col-md-3">
																				<div class="dentalProCost" style={{ textAlign: "left" }}>
																					Frequency & Dosage
																				</div>
																			</th>

																			<th class="col-md-1">
																				<div class="dentalProDis">Intake</div>
																			</th>
																			<th class="col-md-1">
																				<div class="dentalProTotal">Qty</div>
																			</th>
																			<th class="col-md-1">
																				<div class="dentalProNote">Days</div>
																			</th>
																			<th class="col-md-2">
																				<div class="dentalProNote">
																					Start Date
																				</div>
																			</th>
																			<th></th>
																		</tr>
																	</thead>
																	{listObj.prescriptionData.map((item, j) => (
																		<tbody>
																			<span className='position-relative1'>
																				<div class=" ">
																					<span class="elipsis" title="">
																						&nbsp;
																					</span>

																					<label class="dropdown">
																						<div class="dd-button"
																							onClick={() => { drop("dropdownID", item.visitId) }}
																						/>
																						<input
																							type="checkbox"
																							class="dd-input"
																							id="test"
																						/>
																						<ul class="dd-menu" id={"dropdownID" + item.visitId}>
																							{moment(new Date()).format('DD-MMM-YYYY') == listObj.visit_date && item.doctorId == window.sessionStorage.getItem('patientDoctorId') ? (
																								<div>

																									<nav onClick={() => editPrescriptiontyping({
																										data:
																											item.visitId,
																										type: 'typing'
																									})}
																									>
																										<li>
																											<img src={Editicon} style={{ width: '15px', marginRight: '5px' }} />Edit
																										</li>
																									</nav>
																									<a onClick={(e) =>
																										printId(item.visitId, listObj.visit_date, "p", "t", item.prescriptionDetails)}>
																										<li>
																											<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print{' '}
																										</li>
																									</a>
																									{item.prescriptionDetails.find((obj) => obj.indicationAndDosage !== '' || obj.interactions !== '' || obj.majorSideEffects !== '' || obj.drugReactions !== '' || obj.precautions !== '') ?
																										// {obj.indicationAndDosage || obj.interactions || obj.majorSideEffects || obj.drugReactions || obj.precautions ?
																										<a id={"interactionDiv" + j} onClick={(e) =>
																											printId(item.visitId, listObj.visit_date, "printInteraction", "t", item.prescriptionDetails)}>
																											<li>
																												<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print W/
																												<br></br>
																												Interaction{' '}
																											</li>
																										</a>
																										// "ggggg"
																										: ''}
																									<a onClick={(e) => sendSms(item.visitId)}>
																										<li>
																											<img src={Sms} style={{ width: '15px', marginRight: '5px' }} />Send SMS{' '}
																										</li>
																									</a>
																									<a onClick={(e) => sendEmails(item.visitId, listObj.visit_date)}>
																										<li>
																											<img src={sendEmail} style={{ width: '15px', marginRight: '5px' }} />Send Email{' '}
																										</li>
																									</a>
																									{/* <li>Send SMS</li> */}
																								</div>

																							) : <div>
																								<a onClick={(e) => printId(item.visitId, listObj.visit_date, "p", "t", item.prescriptionDetails)}>
																									<li>
																										<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print{' '}
																									</li>
																								</a>
																								{/* {document.getElementById("bbb").value.length ?
																									<a style={{ display: 'block' }} id={"interactionDiv" + j} onClick={(e) => printId(item.visitId, listObj.visit_date, "printInteraction" ,"type")}>
																										<li>
																											<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print W/
																											<br></br>
																											Interaction{' '}
																										</li>
																									</a>
																									 :""}  */}
																								{/* {obj.indicationAndDosage || obj.interactions || obj.majorSideEffects || obj.drugReactions || obj.precautions ? */}
																								{item.prescriptionDetails.find((obj) => obj.indicationAndDosage !== '' || obj.interactions !== '' || obj.majorSideEffects !== '' || obj.drugReactions !== '' || obj.precautions !== '') ?
																									<a id={"interactionDiv" + j} onClick={(e) =>
																										printId(item.visitId, listObj.visit_date, "printInteraction", "t", item.prescriptionDetails)}>
																										<li>
																											<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print W/
																											<br></br>
																											Interaction{' '}
																										</li>
																									</a>
																									: ""}
																								{/* : ''} */}
																								<a onClick={(e) => sendSms(item.visitId)}>
																									<li>
																										<img src={Sms} style={{ width: '15px', marginRight: '5px' }} />Send SMS{' '}
																									</li>
																								</a>
																								<a onClick={(e) => sendEmails(item.visitId, listObj.visit_date)}>
																									<li>
																										<img src={sendEmail} style={{ width: '15px', marginRight: '5px' }} />Send Email{' '}
																									</li>
																								</a>
																							</div>
																							}
																						</ul>
																					</label>
																					{/* ))} */}
																				</div>
																			</span>
																			{item.prescriptionDetails.map((obj, n) => (
																				<tr class="groupBrdr">
																					<td class="col-md-3">
																						<div class="dentalProName"
																							style={{ display: "block" }}>
																							<span class="optionWrapper" style={{ margin: "0px" }}>
																								{(() => {
																									if (obj.status === 'Completed') {
																										return (
																											<img
																												src={tivkmark}
																												title="Completed Test" />
																										);
																									}
																									else if (obj.status === 'Invoice Generated') {
																										return (
																											<img
																												src={img2}
																												title="Invoice Generated"
																											/>
																										);
																									}
																									else {
																										return (
																											<div>
																												<input
																													type="checkbox"
																													id={"pr1" + obj.doctorVisitMedicineId}
																													onChange={(e) => checkedMedicineChange(obj.doctorVisitMedicineId, n, e)}
																													checked={obj.isChecked}
																												/>
																												<label for={"pr1" + obj.doctorVisitMedicineId} />
																											</div>
																										);
																									}
																								})()}
																							</span>
																							<span title={obj.medicineName} className='elipsis' style={{ maxWidth: "120px" }}>{obj.medicineName}</span>
																							{(() => {
																								if (obj.status !== "OPEN") {
																									if (obj.quantity === null || obj.quantity === "null" || obj.quantity === 0 || obj.quantity === "") {
																										if (obj.changedQuantity === null || obj.changedQuantity === "null" || obj.changedQuantity === 0 || obj.changedQuantity === "") {
																											return (
																												<span style={{ color: "#f00" }} title='Not Dispensed'>ND</span>
																											)
																										}
																									}
																									if (parseInt(obj.changedQuantity) > 0) {
																										return (
																											<span style={{ color: "#f00" }} title='Dispensed'>DP</span>
																										)
																									}
																								}
																								else {
																									if (obj.changedQuantity == null || obj.changedQuantity == "null" || obj.changedQuantity == 0 || obj.changedQuantity == "") {
																										return (
																											<span style={{ color: "#f00" }} title='Not Dispensed'>ND</span>
																										)
																									}
																									else if (parseInt(obj.changedQuantity) != 0 && parseInt(obj.changedQuantity) < parseInt(obj.quantity)) {
																										return (
																											<span style={{ color: "#f00" }} title='Partially Dispensed'>PD</span>
																										)
																									}
																									else if (parseInt(obj.changedQuantity) == obj.quantity) {
																										return (
																											<span style={{ color: "#f00" }} title='Dispensed'>DP</span>
																										)
																									}
																									else if (obj.changedQuantity > obj.quantity) {
																										<span style={{ color: "#f00" }} title='Dispensed'>DP</span>
																									}
																								}
																								if (obj.status == "OPEN") {
																									if (obj.quantity == null || obj.quantity == "null" || obj.quantity == 0 || obj.quantity == "") {
																										if (obj.changedQuantity == null || obj.quantity == "null" || obj.changedQuantity == 0 || obj.changedQuantity == "") {
																											if (obj.startDate < new Date()) {
																												return (
																													<span style={{ color: "#f00" }} title='Not Dispensed'>ND</span>
																												)
																											}
																										}
																										if (obj.changedQuantity > 0) {
																											return (
																												<span style={{ color: "#f00" }} title='Dispensed'>DP</span>
																											)
																										}
																									}
																									else {
																										if (listObj.visit_date > new Date()) {
																											return (
																												<span style={{ color: "#f00" }} title='Not Dispensed'>ND</span>
																											)
																										}
																									}
																								}
																							})()}
																						</div>
																					</td>
																					<td class="col-md-2">
																						<div class="dentalProCost" style={{ textAlign: "left" }}>
																							<span>
																								{obj.frequency} -{' '}
																								{obj.dosage}
																							</span>
																						</div>
																					</td>
																					<td class="col-md-1">
																						<div class="dentalProDis" style={{ padding: "5px 0px" }}>
																							{obj.intake}
																						</div>
																					</td>
																					<td class="col-md-1">
																						<div class="dentalProTotal">
																							<span>{obj.quantity}</span>
																						</div>
																					</td>
																					<td class="col-md-1">
																						<div class="dentalProDis">
																							<span>{obj.duration}</span>
																						</div>
																					</td>
																					<td class="col-md-2">
																						<div class="dentalProDis">
																							<span>{obj.startDate}</span>
																						</div>
																					</td>
																					<td class="col-md-2">
																						<span className='medicineDetailDiv'>
																							{obj.interactions !== "" || obj.indicationAndDosage !== "" || obj.majorSideEffects !== "" || obj.precautions !== "" ? (
																								<div id="bbb" className='bbb' >
																									<img
																										src={img1}
																										id="aaa"
																										data-tip={obj.medicineName}
																										data-for={'test' + n}
																										style={{ width: '15px', marginTop: "5px", display: 'block' }}
																										onMouseLeave={(e) => mouseEnterLeaveFunction(e, i + '-' + n)}
																										onMouseOver={(e) => mouseEnterHoverFunction(e, i + '-' + n)} />
																								</div>

																							) : null}

																							<div
																								className="toolTipWrap"
																								id={'pu_generalinfo' + i + '-' + n}
																								style={{ display: 'none' }}
																							>
																								<div>
																									<p>
																										<div class="medicineDetailListCvr">
																											<div class="medicineDetailItems">
																												<span>
																													Interactions
																												</span>
																												{obj.interactions ? (obj.interactions) : ('')}
																											</div>
																											<div class="medicineDetailItems">
																												<span>
																													Indication And Dosage
																												</span>
																												{obj.indicationAndDosage ? (obj.indicationAndDosage) : ('')}
																											</div>
																											<div class="medicineDetailItems">
																												<span>
																													Major Side Effects
																												</span>
																												{obj.majorSideEffects ? (obj.majorSideEffects) : ('')}
																											</div>
																											<div class="medicineDetailItems">
																												<span>
																													Precautions
																												</span>
																												{obj.precautions ? (obj.precautions) : ('')}
																											</div>
																											{/* </div> */}
																										</div>
																									</p>
																								</div>
																							</div>
																						</span>
																					</td>
																				</tr>

																			))}
																			<tr class="dentalProPlaned">
																				<input
																					type="hidden"
																					name="hidTreatmentIds10"
																					id="hidTreatmentIds10"
																					value=""
																				/>
																				<td colspan="12">
																					<div class="plannedBy">
																						Prescribed by {item.PrescribedBy ? 'Dr:' + item.PrescribedBy : ''}
																						<span />
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	))}
																</table>
															</div>
														</div>
													))}
													<div id="popup1" class="popup-container popup-containercaseSheet">
														<div class="popup-content">
															<a href="#" class="close">
																&times;
															</a>
															<h3>Prescription</h3>
															<img
																className="priscriptionThumbnailPopup"
																id="priscriptionThumbnailPopup"
																src={Priscription}
															/>
														</div>
													</div>
												</div>
											) : (
												<NoMedicineFound />
											)}
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PatientPriscription;
