import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Priscription from '../images/media/prescription.png';
import Editicon from '../images/icons/edit-bg.png';
import Print from '../images/icons/printer_icon.png';
import Delete from '../images/icons/delete_btn.png';
import { callingAPI } from '../config';
import moment from 'moment';
import Loader from '../Common/loader';
import Sms from '../images/icons/chat.png';
import sendEmail from '../images/icons/sendEmail.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function CasesheetMedical() {
	const [caseSheetMedical, setCaseSheetMedical] = useState([]);
	const [image, setImage] = useState([]);
	const [load, setLoad] = useState(false);
	const [imageCaseSheet, setImageCaseSheet] = useState('');
	const [imageArrs, setImageArrs] = useState(process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH);
	const [doctorVisitId, setdoctorVisitId] = useState('');
	const [imageDate, setImageDate] = useState([]);
	const [redirectState, setRedirectState] = useState('');
	const [smsFlag, setSmsFlag] = useState('')
	const [emailFlag, setEmailFlag] = useState('')
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	useEffect(() => {
		window.sessionStorage.setItem('editId', '');
		window.sessionStorage.setItem('editImageId', '');
		let postData = {
			// doctorId: window.sessionStorage.getItem('patientDoctorId'),
			// userId: window.sessionStorage.getItem('userId'), 
			doctorId: "",
			// userId: "", // As per the discussion with siva,"userId": "" should have the value so added session storage value
			userId: window.sessionStorage.getItem('userId'),
			memberId: window.sessionStorage.getItem('memberId'),
			doctor_visit_id: '',
			fromPage: '',
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('commonUserFunctions/clinicalNotes', postData)
			.then((response) => {

				if (response.data.success === '1') {
					setLoad(true)
					setCaseSheetMedical(response.data.result.caseSheet);
					setImage(response.data.result.clinclNoteWriteDateData);
					setSmsFlag(response.data.result.smsFlag)
					setEmailFlag(response.data.result.emailFlag)
					let imageRedirect = false;
					let textRedirect = false;
					if (response.data.result.clinclNoteWriteDateData.length > 0) {
						setImageDate(response.data.result.clinclNoteWriteDateData[0].dataCreatedOn);

						response.data.result.clinclNoteWriteDateData.map((data, j) => setImageCaseSheet(data.image));
					}
					// else if (response.data.result.caseSheet.length > 0) {

					// }
					for (let j = 0; j < response.data.result.clinclNoteWriteDateData.length; j++) {
						imageRedirect =
							moment(response.data.result.clinclNoteWriteDateData[j].dataCreatedOn).format(
								'YYYY-MM-DD'
							) == moment(new Date()).format('YYYY-MM-DD');
						let drCheck1 = (response.data.result.clinclNoteWriteDateData[j].doctorId == window.sessionStorage.getItem('patientDoctorId'))
						if (imageRedirect === true && drCheck1 === true) {
							setRedirectState('image');
							setdoctorVisitId(response.data.result.clinclNoteWriteDateData[j].dvrId);
							return false;
						} else {
							setRedirectState('')
						}
					}
					for (let i = 0; i < response.data.result.caseSheet.length; i++) {
						textRedirect =
							moment(new Date(response.data.result.caseSheet[i].CreatedOn)).format('YYYY-MM-DD') ==
							moment(new Date()).format('YYYY-MM-DD');
						let drCheck = (response.data.result.caseSheet[i].caseSheetObjDoctorId == window.sessionStorage.getItem('patientDoctorId'))
						if (textRedirect === true && drCheck === true) {
							setRedirectState('text');
							setdoctorVisitId(response.data.result.caseSheet[i].DoctorVisitId);
							return false;
						} else {
							setRedirectState('');
						}
					}
					// let imageRedirect =
					// 	moment(response.data.result.clinclNoteWriteDateData[0].dataCreatedOn).format('YYYY-MM-DD') ==
					// 	moment(new Date()).format('YYYY-MM-DD');
					// if (textRedirect === true) {
					// 	setRedirectState('text');
					// 	setdoctorVisitId(response.data.result.caseSheet[0].DoctorVisitId);
					// } else if (imageRedirect === true) {
					// 	setRedirectState('image');
					// 	setdoctorVisitId(response.data.result.clinclNoteWriteDateData[0].dvrId);
					// } else {
					// 	setRedirectState('');
					// }
					setImageArrs(
						process.env.REACT_APP_S3_BUCKET_URL +
						process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
						process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH
					);
				}
			})
			.catch();
	}, []);
	const imageClick = (data) => {
		document.getElementById('priscriptionThumbnailPopup').src = data;
	};
	const Edit = (data) => {
		// if(caseSheetMedical.length == 0 ){
		// 	window.sessionStorage.setItem('historynumb', 0);
		// }else{
		// 	window.sessionStorage.setItem('historynumb', 1);
		// }
		window.sessionStorage.setItem('editId', data);
		window.location.href = '/patients/casesheetMedicalAdd';
	};
	const EditImage = (value) => {
		// if(image.length == 0 ){
		// 	window.sessionStorage.setItem('historynumb', 0);
		// }else{
		// 	window.sessionStorage.setItem('historynumb', 1);
		// }
		window.sessionStorage.setItem('editImageId', value);
		window.location.href = '/patients/casesheetMedicalAddWrite';
	};
	const printId = (data, medBrId, drId) => {

		// window.location.href = '/patients/casesheetMedicalPrint';
		if (medBrId === 4) {
			window.sessionStorage.setItem('drId', drId);
			window.sessionStorage.setItem('printId', data);
			window.open(
				`${process.env.PUBLIC_URL}/patients/casesheetDentalPrint/${window.sessionStorage.getItem('memberId') + '/' + drId + '/' + data + '/' + localStorage.getItem('hospitalId')} `,
				"_blank"
			);

		}
		else {
			window.sessionStorage.setItem('drId', drId);
			window.sessionStorage.setItem('printId', data);
			window.open(
				`${process.env.PUBLIC_URL}/patients/casesheetMedicalPrint/${window.sessionStorage.getItem('memberId') + '/' + drId + '/' + data + '/' + localStorage.getItem('hospitalId')} `,
				"_blank"
			);
		}
	};
	const redirect = () => {
		// if(image.length == 0 && caseSheetMedical.length == 0 ){
		// 	window.sessionStorage.setItem('historynumb', 0);
		// }else{
		// 	window.sessionStorage.setItem('historynumb', 1);
		// }
		if (redirectState === 'text') {
			window.location.href = '/patients/casesheetMedicalAdd';
			window.sessionStorage.setItem('editId', doctorVisitId);
		} else if (redirectState === 'image') {

			window.location.href = '/patients/casesheetMedicalAddWrite';
			window.sessionStorage.setItem('editImageId', doctorVisitId);
		} else {
			window.location.href = '/patients/casesheetMedicalAdd';
		}
	};
	const deleteFunction = (id, divid) => {
		var x = false;
		x = window.confirm('Do you want to Delete?');
		if (x === true) {
			const postData = {
				doctorVisitId: id,
				userId: window.sessionStorage.getItem('userId'),
			};
			callingAPI('commonUserFunctions/reviewTypdeleteClinicalNoteseView', postData)
				.then((data) => {
					if (data.data.success === '1') {
						window.location.href = '/patients/casesheetMedical';
						toast.success('Successfully Deleted');
					} else {
						toast.error(data.data.errorMessage);
					}
				})
				.catch();
		}
	};
	const deletedFunction = (id, divid) => {
		const postData = {
			doctorVisitId: id,
		};
		callingAPI('commonUserFunctions/deletePmmemberComplaintsWrite', postData)
			.then((data) => {
				if (data.data.success === '1') {
					// drop(divid, id)
					window.location.href = '/patients/casesheetMedical';
					toast.success('Successfully Deleted');
				} else {
					toast.error(data.data.errorMessage);
				}
			})
			.catch();
	};
	const drop = (divid, i) => {

		var elementDisplay = document.getElementById(divid + i);
		elementDisplay.style.display = (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divid + i,
			count: (dropDownCounter.id === divid + i && dropDownCounter.count === 1) ? 0 : 1
		})
		// alert(elementDisplay.style.display)
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divid + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	const sendSms = (id) => {
		const postData = {
			"doctorVisitId": id,
			"mode": "caseSheet",
			"siteId": ""
		};
		callingAPI('commonUserFunctions/sendCaseSheetSMSToUser', postData)
			.then((data) => {
				if (data.data.success === '1') {
					window.location.href = '/patients/casesheetMedical';
				}
			})
			.catch();
	};
	const sendEmails = (id) => {
		const postData = {
			"doctorVisitId": id,
		};
		callingAPI('commonUserFunctions/sendMailToUserCaseSheet', postData)
			.then((data) => {
				if (data.data.success === '1') {
					window.location.href = '/patients/casesheetMedical';
				}
			})
			.catch();
	}
	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap plMdcl">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />

					<div className="practiceManagementWrap">

						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{load === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">CASE SHEET &nbsp;</span>

												<span class="treatmentPlanBtn pl_blue_btn">
													<a onClick={(e) => redirect()}>+ Add</a>
												</span>
												{image.length > 0 || caseSheetMedical.length > 0 ? (
													<span class="treatmentPlanBtn pl_blue_btn">
														<a href="/patients/casesheetMedicalHistory">View History</a>
													</span>) : ""}
											</div>
											{/* ==========   IMAGE BEGINS ========= */}

											{caseSheetMedical.length > 0 || image.length > 0 ?
												<div>
													<div class="treatmentPlanList1" style={{ height: 'auto' }}>
														{image.map((item, i) => (
															<div class="treatmentTable planActive">
																<div class="treatmentPlanDate">{item.dataCreatedOn}</div>
																<table className="treatmentComplaintWrap">
																	<tbody>
																		<tr class="groupBrdr">
																			<td colspan="10">
																				<div class="treatmentTable prescriptionTable">
																					<div
																						class="drawTumbVisit"
																						style={{ width: '100%' }}
																					>
																						<a>
																							<div
																								class="drawTumbItemVisit thumbPopup"
																								id="drawTumbItemVisit0"
																							>
																								{item.image
																									.split(',')
																									.map((itemImg, i) => (
																										<div class="popup-link">
																											<a
																												href="#popup1"
																												onClick={() => {
																													imageClick(
																														imageArrs +
																														'/'+
																														
																														itemImg
																													);
																												}}
																											>
																												<img
																													className="priscriptionThumbnail"
																													src={imageArrs+'/' + itemImg}
																												/>
																											</a>
																										</div>
																									))}
																							</div>
																						</a>
																					</div>
																				</div>
																			</td>

																			<td colspan="10">
																				<div
																					class="dentalProNote caseSheetMdclDpdwn"
																					style={{ padding: '0px' }}
																				>
																					<span class="elipsis" title="">
																						&nbsp;
																					</span>

																					<label class="dropdown">
																						<div class="dd-button" />

																						<input
																							type="checkbox"
																							class="dd-input"
																							id="test"
																						/>

																						<ul class="dd-menu">
																							{item.status == 1 ?
																								<div>
																									{item.doctorId == window.sessionStorage.getItem('patientDoctorId') ?
																										<a
																											onClick={(e) =>
																												EditImage(item.dvrId)}
																											style={{
																												textDecoration: 'none',
																												color: 'black',
																											}}
																										>
																											<li>
																												<img src={Editicon} style={{ width: '15px', marginRight: '5px' }} />Edit
																											</li>
																										</a> : ""}
																									<a
																										onClick={(e) =>
																											printId(item.dvrId, item.medicineBranchId, item.doctorId)}
																									// style={{
																									// 	textDecoration: 'none',
																									// 	color: 'black',
																									// }}
																									>
																										<li>
																											<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print
																										</li>
																									</a>
																									{item.doctorId == window.sessionStorage.getItem('patientDoctorId') ?
																										<a
																											onClick={(e) =>
																												deletedFunction(item.dvrId, item.medicineBranchId, item.doctorId)}
																											style={{
																												textDecoration: 'none',
																												color: 'black',
																											}}
																										>
																											<li>
																												<img src={Delete} style={{ width: '15px', marginRight: '5px' }} />Delete
																											</li>
																										</a> : ""}
																								</div>
																								:
																								<div>
																									<a
																										onClick={(e) =>
																											printId(item.dvrId, item.medicineBranchId, item.doctorId)}
																									// style={{
																									// 	textDecoration: 'none',
																									// 	color: 'black',
																									// }}
																									>
																										<li>
																											<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print
																										</li>
																									</a>
																								</div>
																							}
																						</ul>
																					</label>
																				</div>
																			</td>
																		</tr>

																		<tr class="dentalProPlaned" style={{ background: 'transparent' }}>
																			<input
																				type="hidden"
																				name="hidTreatmentIds10"
																				id="hidTreatmentIds10"
																				value=""
																			/>
																			<td colspan="12">
																				<div class="plannedBy">
																					Prescribed by{' '}
																					<span>{item.displayName}</span>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														))}
													</div>

													{/* ================   IMAGE ENDS HERE  ================ */}

													{caseSheetMedical.map((item, i) => (
														<div class="treatmentPlanList1">
															<div class="treatmentPlanDate">{item.CreatedOn}</div>
															<div class="treatmentComplaintWrap">
																{item.caseSheetCompData.map((data, j) => (
																	<div class="treatmentComplaintRowWrap">
																		<div class="treatmentComplaintRow">
																			<div class="treatmentComplaintLeft">
																				{data.Title}
																			</div>
																			<div class="treatmentComplaintRight">
																				<span>
																					{data.Tags ? (
																						<span class="treatmentTAgName ">

																							{data.Tags
																								.split(',')
																								.map((data1) => (
																									<div>{data1}</div>
																								))}
																						</span>
																					) : (
																						''
																					)}
																					{data.SinceDate ? (
																						<span class="treatmentTAgDate">
																							{data.SinceDate}
																						</span>
																					) : (
																						''
																					)}
																				</span>
																				{data.Description ? (
																					<div>
																						<span style={{ fontWeight: 'bold' }}>
																							Description :
																						</span>
																						<span>
																							{data.Description}
																							<br />
																						</span>
																					</div>
																				) : (
																					''
																				)}
																			</div>
																		</div>
																	</div>
																))}
																{/* ======== Diagnosis ============ */}

																{/* <div class="treatmentComplaintRowWrap">
																<div class="treatmentComplaintRow">
																	<div class="treatmentComplaintLeft">
																	Diagnosis<br />
																	<span style={{ color: "red", display: "block", fontSize: "10px" }}>(ICD-10)<sup>beta</sup></span>
																	</div>
																	<div class="treatmentComplaintRight">
																			<div>
																				<span>
																					Diagnosis(ICD-10)
																			<br />

																				</span>
																			</div>
																	</div>
																</div>
															</div> */}
																{/* ========== Diagnosis ========== */}

																{item.systemReviewData.map((data, j) => (
																	<div class="treatmentComplaintRow" id="systemReviewId">
																		<div class="treatmentComplaintLeft">System Review</div>

																		<div>
																			<div class="treatmentComplaintLeft">&nbsp;</div>
																			<div class="treatmentComplaintRight">
																				<span>
																					<span
																						class="treatmentTAgName treatmentTAgSystem elipsis"
																						style={{ fontWeight: 'bold' }}
																					>
																						{data.Name}
																					</span>
																				</span>
																				{data.sysRevSymptom ? (
																					<span class="treatmentTAgName  elipsis">
																						{data.sysRevSymptom
																							.split('</br>')
																							.map((data2) => (
																								<div>{data2}</div>
																							))}
																					</span>) : (
																					''
																				)}
																				{data.sysRevDescription ? (
																					<div>
																						<span style={{ fontWeight: 'bold' }}>
																							Description :
																						</span>
																					</div>) : (
																					''
																				)}
																				<span>{data.sysRevDescription}</span>
																			</div>
																		</div>

																	</div>
																))}

																<div className="plcasesheet">
																	<div class="dentalProNote" style={{ padding: '0px' }}>
																		<span class="elipsis" title="">
																			&nbsp;
																		</span>

																		<label class="dropdown">
																			<div class="dd-button" onClick={() => {
																				drop("dropdownID", item.DoctorVisitId)
																			}} />

																			<input type="checkbox" class="dd-input" id="test" />

																			<ul class="dd-menu" id={"dropdownID" + item.DoctorVisitId}>
																				{item.Status == 0 ?
																					<div>
																						{item.caseSheetObjDoctorId == window.sessionStorage.getItem('patientDoctorId') ?
																							<a onClick={(e) => Edit(item.DoctorVisitId)} style={{ textDecoration: 'none', color: 'black', }}>
																								<li>
																									<img src={Editicon} style={{ width: '15px', marginRight: '5px' }} />Edit
																								</li>
																							</a>
																							: ""}
																						<a
																							onClick={(e) =>
																								printId(item.DoctorVisitId, item.medicineBranchId, item.caseSheetObjDoctorId)}
																						// style={{
																						// 	textDecoration: 'none',
																						// 	color: 'black',
																						// }}
																						>
																							<li>
																								<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print
																							</li>
																						</a>
																						{item.caseSheetObjDoctorId == window.sessionStorage.getItem('patientDoctorId') ?
																							<a
																								onClick={(e) =>
																									deleteFunction(item.DoctorVisitId, "dropdownID")}
																								style={{
																									textDecoration: 'none',
																									color: 'black',
																								}}
																							>
																								<li>
																									<img src={Delete} style={{ width: '15px', marginRight: '5px' }} />Delete
																								</li>
																							</a> : ""}
																						{smsFlag == true ?
																							<a
																								onClick={() => sendSms(item.DoctorVisitId)}
																								style={{
																									textDecoration: 'none',
																									color: 'black',
																								}}
																							>
																								<li>
																									<img src={Sms} style={{ width: '15px', marginRight: '5px' }} />Send SMS
																								</li>
																							</a> :
																							''}
																						{emailFlag == true ?
																							<a
																								onClick={(e) => sendEmails(item.DoctorVisitId)}
																								style={{
																									textDecoration: 'none',
																									color: 'black',
																								}}
																							>
																								<li>
																									<img src={sendEmail} style={{ width: '15px', marginRight: '5px' }} />Send Email
																								</li>
																							</a> :
																							''}
																					</div>
																					: <div>
																						<a
																							onClick={(e) =>
																								printId(item.DoctorVisitId, item.medicineBranchId, item.caseSheetObjDoctorId)}
																						// style={{
																						// 	textDecoration: 'none',
																						// 	color: 'black',
																						// }}
																						>
																							<li>
																								<img src={Print} style={{ width: '15px', marginRight: '5px' }} />Print
																							</li>
																						</a>
																					</div>
																				}

																			</ul>
																		</label>
																	</div>
																</div>
																<div class="treatmentComplaintNoted">
																	Noted by <span>{item.caseSheetObjDoctorName}</span>
																</div>
															</div>
														</div>
													))}
												</div> : (<div className="practiceManagementForm" style={{ border: '1px solid #dadada' }}>
													<div className="noDataFound">
														<p>No Case Sheet Found</p>
													</div>
												</div>)}
											<div id="popup1" class="popup-container">
												<div class="popup-content">
													<a href="#" class="close">
														&times;
													</a>
													<h3>Case Sheet</h3>
													<img
														className="priscriptionThumbnailPopup"
														id="priscriptionThumbnailPopup"
														src={Priscription}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default CasesheetMedical;
