import React, { useEffect, useState, useRef, useCallback } from 'react';
import { callingAPI } from '../config';
import Cropper from 'react-easy-crop'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'
import style from './style.jsx'
import AWS from 'aws-sdk';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadToS3 } from '../uploadToS3';

var s3;
function Doctorimage(props, classes) {
	const [file, setFile] = useState('');
	const [imageName, setImageName] = useState('');
	const [imagePreview, setImagePreview] = useState();
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [rotation, setRotation] = useState(0)
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [myimage, setMyImage] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null)
	const [newimage, setnewimage] = useState()
	const [flag, setflag] = useState(false)
	// const [isButtonDisabled, setIsButtonDisabled] = useState(false)
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])
	const uploadImage = e => {
		setFile(e.target.files[0]);
		if (e.target.files[0].size > 3000000) {
			toast.error("Image size should less than 3 MB");
			return false;
		}
		else if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
			toast.error("Please attach jpg,jpeg or png files");
			return false;
		}
		else {
			setImageName(e.target.files[0].name.replace(/ /g, '-'));
			setMyImage(URL.createObjectURL(e.target.files[0]));
			setCroppedImage(croppedImage)
			setflag(true)
		}
	};
	var getS3folderHospitalLogo =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH + "/";
	const showCroppedImage = useCallback(async () => {
		// alert("showCroppedImageeeeee")

		try {
			const croppedImage = await getCroppedImg(
				myimage,
				croppedAreaPixels,
				rotation
			)

			if (file != '') {
				setCroppedImage(croppedImage)
				// alert('trueeeeeeeeeee');
				if (file.size < 3000000) {
					setCroppedImage(croppedImage)
					fetch(croppedImage)
						.then((res) => res.blob())
						.then((myBlob) => {
							let reader = new FileReader();
							reader.readAsDataURL(myBlob);
							reader.onloadend = async function (e) {
								var filess = reader.result;
								function dataURLtoFile(dataurl, filename) {
									var arr = dataurl.split(','),
										mime = arr[0].match(/:(.*?);/)[1],
										bstr = atob(arr[1]),
										n = bstr.length,
										u8arr = new Uint8Array(n);

									while (n--) {
										u8arr[n] = bstr.charCodeAt(n);
									}
									return new File([u8arr], filename, { type: mime });
								}
								var filee = dataURLtoFile(filess, imageName);
								const base64 = filess
								let imagefileName = "doctor" + Date.parse(new Date()) + ".png";
								setnewimage(imagefileName)
								const buf = Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')
								let S3BucketMainFolder =
									process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH + "/";
								let fullFilePath = S3BucketMainFolder + imagefileName;
								handleImageUpload(base64, imagefileName)
								// const s3Url = await uploadToS3(file, process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH)
								// 		  console.log("🚀 ~ imageS3Save ~ s3Url 1:", s3Url)
								// s3.upload(
								// 	{
								// 		Key: fullFilePath,
								// 		Body: buf,
								// 		ACL: 'public-read',
								// 	},
								// 	(err, data) => {
								// 		if (err) {
								// 			toast.error('There was an error uploading your photo: ' + err.message);
								// 			return null;
								// 		} else {
								// 			window.location.href = '/Dashboard/profile';
								// 		}
								// 	}
								// );
							}

						});

				}

			}
			await saveImageDetails1();
			setCroppedImage(croppedImage)
		} catch (e) {

		}

	}, [croppedAreaPixels, rotation])
	const onClose = useCallback(() => {
		setCroppedImage(null)
	}, [])
	const handleImageUpload = (base64url, fileName) => {
		return new Promise(async (resolve, reject) => {
			try {
				const base64Data = base64url.split(',')[1];
				const byteCharacters = atob(base64Data);
				const byteArrays = [];
				for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
					const slice = byteCharacters.slice(offset, offset + 1024);
					const byteNumbers = new Array(slice.length);
					for (let i = 0; i < slice.length; i++) {
						byteNumbers[i] = slice.charCodeAt(i);
					}
					const byteArray = new Uint8Array(byteNumbers);
					byteArrays.push(byteArray);
				}

				const blob = new Blob(byteArrays, { type: 'image/webp' });
				// await uploadToS3(blob,process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH,fileName)
				await uploadToS3(blob, process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH, fileName)
					.then((imageUrl) => {
						resolve(imageUrl);
					})
					.catch((error) => {
						console.error("Error uploading image to S3:", error);
						reject(error);
					});
			} catch (error) {
				console.error("Error in handleImageUpload:", error);
				reject(error);
			}
		});
	};
	//   crop
	AWS.config.update({
		// accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	const handleImageUploadOnChange = (e) => {
		setFile(e.target.files[0]);
		setImageName(e.target.files[0].name.replace(/ /g, '-'));
		setImagePreview(URL.createObjectURL(e.target.files[0]));
	};
	//  fileUpload();
	const saveImageDetails1 = () => {
		toast.success("Doctor Image Uploaded Successfully")
		if (!imageName) {
			toast.error('please upload image');
			return null;
		}
		const postData = {
			subFunctionName: 'updateImageDetails',
			doctorImageName: "doctor" + Date.parse(new Date()) + ".png",
			doctorId: window.sessionStorage.getItem('doctorId'),
			doctorPhoto: '',
			doctorImageId: props.doctorImage && props.doctorImage.doctorImageId ? props.doctorImage.doctorImageId : '',
		};
		callingAPI('doctorProfile/updateDoctorProfile', postData)
			.then((res) => {
				if (res.data.success === '1') {
					// alert("Profile Updated Successfully");
					// window.location.href = '/Dashboard/profile';

					// else{
					// 	alert("please upload image less")
					// }
				} else {
					toast.error(res.data.errorMessage);
				}
				// alert(res.data.successMessage);
			})
			.catch();

		// fileUpload();

	};
	const fileUpload = () => {
		// alert("imagesave")
	};
	useEffect(
		() => {
			if (props.doctorImage) {
				setImageName(props.doctorImage.doctorImageName);
				setImagePreview(
					process.env.REACT_APP_S3_BUCKET_URL +
					process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
					process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH +
					'/' +
					props.doctorImage.doctorImageName
				);
			}

		},
		[props.doctorImage]
	);
	return (
		<div>
			<div className="homeProfileDetails">
				<ToastContainer position="bottom-right" />
				<span class="uploadButton uploadButtonIarge" style={{ float: 'left', paddingLeft: '0px' }}>
					<input
						type="file"
						accept="image/png, image/jpeg"
						id="file1"
						name="file1"
						onChange={(e) => uploadImage(e)}
					/>
					<label
						for="file1"
						style={{ background: '#e7e7e7', color: '#393939', marginRight: '5px', minWidth: "190px", textAlign: "center" }}
					>
						Browse
					</label>

				</span>

				{/* preview immg begins here */}
				<span style={{ paddingLeft: "0px" }}>
					<div
						className="filUploadInline"
						style={{
							display: myimage || getS3folderHospitalLogo + window.sessionStorage.getItem('setDoctorImage') ? '' : 'none',
							height: '50px',
							width: '50px',
							overflow: 'hidden',
							objectFit: 'contain',
							margin: '0 10px', marginBottom: "10px"
						}}
						id="imgDiv"
					>
						<img id="thumbImgnormal" src={myimage || getS3folderHospitalLogo + window.sessionStorage.getItem('setDoctorImage')} style={{ width: '100%' }} />
					</div>
				</span>
				{/* preview img ends here */}
				<div className="practiceManagementGrid profileForm">
					<div className="row rowMargin">
						<div className="col-md-12 col-lg-12 practiceManagementList">
							<div className={classes.cropContainer}>
								<div className={classes.controls}>

									{flag == true &&
										<div style={{ padding: '128px' }}>

											<Cropper
												image={myimage}
												crop={crop}
												rotation={rotation}
												zoom={zoom}
												aspect={1 / 1}
												onCropChange={setCrop}
												onRotationChange={setRotation}
												onCropComplete={onCropComplete}
												onZoomChange={setZoom}
											/></div>}
								</div>
							</div>
							<div className="filUploadInline" style={{ display: 'none' }} id="imgDiv">
								<img id="thumbImgnormal" style={{ width: '100%' }} />
							</div>
							<div className="uploadTextMessage w-100 text-start">
								Select a recent photograph of yourself in jpg/png/gif format to upload.
							</div>
							{/* <p className="filUploadText">{imageName}</p> */}
						</div>
						<div className="col-md-4 col-lg-4 practiceManagementList my-0 py-0">
							<span
								class="borderButton borderButtonLarge legoButton_green drImageUpload"
								style={{ width: '100%' }}
								onClick={() => { showCroppedImage() }}
							// disabled={isButtonDisabled}
							>
								Upload
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default withStyles(style)(Doctorimage)
