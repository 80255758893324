import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Image from '../images/media/imanews.jpg';
import Add from '../images/icons/add.png';
import Close from '../images/icons/close.png';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import AWS from 'aws-sdk';
import WebcamCaptureDental from './webcamDental';
import Loader from '../Common/loader';
import $ from 'jquery';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadToS3 } from '../uploadToS3';
var s3;
function PatientFilesAdd() {
	$(document).ready(function () {
		$('#filesAdd').addClass("testResultAdd");

	})
	//const [image, setImage] = useState("")
	const [base64, setBase64] = useState('');
	const [file, setFile] = useState('');
	const [imagePath, setImagePath] = useState('');
	const [imagePreview, setImagePreview] = useState(null);
	const [imagePreviewAddMore, setImagePreviewAddMore] = useState(null);
	const [imageName, setImageName] = useState();
	const [photo, setPhoto] = useState();
	const [state, setState] = useState('');
	const [addValue, setAddValue] = useState([]);
	const [addValue1, setAddValue1] = useState([]);
	const [type1, setType1] = useState('');
	const [type2, setType2] = useState('');
	const [type4, setType4] = useState('');
	const [isDataDefault] = useState(0);
	const [load, setLoad] = useState(false);
	const [disable, setDisable] = React.useState(false)
	const [capturedData, setData] = useState({});
	const [newaddValue, setNewaddValue] = useState('')
	var copyArray = [];
	AWS.config.update({
		// accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	// const onTypeChange1 = (event) => {
	// 	const { name, value } = event.target;
	// 	setType2(event.target.value);
	// };
	const onTypeChange2 = (event) => {
		const { name, value } = event.target;
		setType2(event.target.value);
	};
	const onTypeChange4 = (event) => {
		const { name, value } = event.target;
		setType4(event.target.value);
	};
	const categoryTypeNewChange = (e, i) => {
		const { name, value } = e.target;
		const values = [...addValue];
		values[i] = { ...values[i], [name]: value };
		setAddValue(values);
	};
	const onChangeFunction = (event) => {
		//setType2(event.target.value);
		if (document.getElementById('txtTypeOne').value == '1') {
			setType2("1")
			document.getElementById('txtTypeTwo').style.display = 'block';
			document.getElementById('txtTypeThree').style.display = 'none';
			document.getElementById('txtTypeFour').style.display = 'none';
			document.getElementById('aktest').style.display = 'none';
		} else if (document.getElementById('txtTypeOne').value == '2') {
			setType2("Before")
			document.getElementById('txtTypeThree').style.display = 'block';
			document.getElementById('txtTypeFour').style.display = 'none';
			document.getElementById('txtTypeTwo').style.display = 'none';
			document.getElementById('aktest').style.display = 'none';
		} else if (document.getElementById('txtTypeOne').value == '3') {
			setType2("Other")
			document.getElementById('txtTypeFour').style.display = 'block';
			document.getElementById('txtTypeTwo').style.display = 'none';
			document.getElementById('txtTypeThree').style.display = 'none';
			document.getElementById('aktest').style.display = 'none';
		} else if (document.getElementById('txtTypeOne').value == '0') {
			document.getElementById('txtTypeFour').style.display = 'none';
			document.getElementById('txtTypeTwo').style.display = 'none';
			document.getElementById('txtTypeThree').style.display = 'none';
			document.getElementById('aktest').style.display = 'block';
		}
		const { name, value } = event.target;
		setType1(event.target.value);
	};
	const addWebCam = () => {
		toast.error('Opening Webcam');
		setState({
			isDataDefault: 1,
		});
	};
	const handleImageUploadOnChange = (e, i) => {
		setBase64('')
		setFile(e.target.files[0]);
		setImageName(e.target.files[0].name);
		// setImageName(new Date().getTime() + e.target.files[0].name);
		if (e.target.files[0].size > 3000000) {
			toast.error("Image size should less than 3 MB");
			return false;
		}
		else if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
			toast.error("Please attach jpg,jpeg or png files");
			return false;
		}
		else {
			setImagePreview(URL.createObjectURL(e.target.files[0]));
			setImagePreviewAddMore(URL.createObjectURL(e.target.files[0]));
		}
	};
	const webCamImageFunction = (image, base64) => {
		// setImageName(new Date().getTime() + image);
		setImageName(image);
		setBase64(base64);
		// setState({
		// 	isData: 1,
		// });
	};
	const onCaptureHandler = (captureData) => {
		setData(captureData);
	};
	const addAchievements = (e, index) => {

		let flag = true;
		if (document.getElementById('txtTypeOne').value === '0') {
			toast.error('Type required');
			flag = false;
		} else if (type2 === '') {
			toast.error('description required');
			flag = false;
		} else if (!imageName) {
			toast.error('Image required');
			flag = false;
		}
		if (flag === true) {
			var copyaddValue = [...addValue];
			var newaddValue = {
				description: type2,
				imageName: imageName,
				imageTypeId: type1,
				folderName: type4,
				imageNameFile: imagePreview ? imagePreview : "",
				file: file,
				base64: base64,
				capturedData: capturedData,
				isData: 0
			};
			setAddValue([...copyaddValue, newaddValue]);
			setType2('1');
			setType1('');
			setImageName('')
			// setAddValue(setNewaddValue);
			setImagePreview(null);
			setType4('');
			document.getElementById('txtTypeFour').style.display = 'none';
			document.getElementById('txtTypeTwo').style.display = 'none';
			document.getElementById('txtTypeThree').style.display = 'none';
			document.getElementById('aktest').style.display = 'block';
			setState({
				isDataDefault: 0,
			});
		}
	};
	const removeRow = (e, i) => {
		let copyAppendValues = [...addValue];
		copyAppendValues.splice(i, 1);
		setAddValue(copyAppendValues);
	};
	const patientFileSave = () => {
		setLoad(false);
		setDisable(false)
		setImageName()
		let flag = true;
		let copyAppendValues = [...addValue];
		if (copyAppendValues.length === 0) {

			if (document.getElementById('txtTypeOne').value === '0') {
				toast.error('Type required');
				flag = false;
			} else if (type2 === '') {
				toast.error('description required');
				flag = false;
			} else if (!imageName) {
				toast.error('Image required ');
				flag = false;
			}
			else if (type2 !== '' && imageName != "" && type1 !== "0") {
				var a = {
					description: type2,
					imageName: imageName,
					imageTypeId: type1,
					folderName: type4,
					imageNameFile: imagePreview ? imagePreview : "",
					file: file,
					capturedData: capturedData,
					base64: base64
				};
				copyAppendValues.push(a)
				flag = true;
			}
		}
		else {
			if (document.getElementById('txtTypeOne').value === '0') {
				toast.error('Type required');
				flag = false;
			} else if (type2 === '') {
				toast.error('description required');
				flag = false;
			}
			else if (!imageName) {
				toast.error('Image required ');
				flag = false;
			}
			else if (type2 !== '' && imageName != "" && type1 !== "0") {
				var a = {
					description: type2,
					imageName: imageName,
					imageTypeId: type1,
					folderName: type4,
					imageNameFile: imagePreview ? imagePreview : "",
					file: file,
					capturedData: capturedData,
					base64: base64
				};
				copyAppendValues.push(a)
				flag = true;
			}
		}
		if (flag === true) {
			setLoad(false);
			setDisable(true)
			const postdata = {
				userId: window.sessionStorage.getItem('userId'),
				memberId: window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
				scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
				appointmentDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
				dentalInsertArray: copyAppendValues
			};
			callingAPI('clinicalBackground/savePatientDentalFiles', postdata)
				.then((res) => {
					if (res.data.success === '1') {
						imageS3Save(copyAppendValues);
						setDisable(true)
						setLoad(true);

					} else {
						setDisable(true)
						setLoad(true);
					}
				})
				.catch();
		}
	};
	const handleImageUpload = (base64url,fileName) => {
		return new Promise(async(resolve, reject) => {
		  try {
			const base64Data = base64url.split(',')[1];
			const byteCharacters = atob(base64Data);
			const byteArrays = [];
			for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
			  const slice = byteCharacters.slice(offset, offset + 1024);
			  const byteNumbers = new Array(slice.length);
			  for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			  }
			  const byteArray = new Uint8Array(byteNumbers);
			  byteArrays.push(byteArray);
			}
			const blob = new Blob(byteArrays, { type: 'image/webp' });
			const s3Url = await uploadToS3(blob,process.env.REACT_APP_PATIENT_DENTALFILES_S3_IMAGE_PATH,fileName)
		  } catch (error) {
			console.error("Error in handleImageUpload:", error);
			reject(error);
		  }
		});
	  };
	const imageS3Save = async (copyAppendValues) => {
		for (let i = 0; i < copyAppendValues.length; i++) {
			var S3BucketMainFolder =
				process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_DENTALFILES_S3_IMAGE_PATH;
			var fullFilePath = S3BucketMainFolder + '/' + copyAppendValues[i].imageName;
			var fileName = copyAppendValues[i].file
			if (copyAppendValues[i].base64 === '') {
				const s3Url = await uploadToS3(fileName, process.env.REACT_APP_PATIENT_DENTALFILES_S3_IMAGE_PATH)
				console.log("🚀 ~ imageS3Save ~ s3Url 2:", s3Url)
				if (i === (copyAppendValues.length) - 1) {
									window.location.href = '/patients/patientFiles';
								}
				// await s3.upload(
				// 	{
				// 		Key: fullFilePath,
				// 		Body: fileName,
				// 		ACL: 'public-read',
				// 	},
				// 	(err, data) => {
				// 		if (err) {
				// 			toast.error('There was an error uploading your photo: ' + err.message);
				// 		} else {
				// 			if (i === (copyAppendValues.length) - 1) {
				// 				window.location.href = '/patients/patientFiles';
				// 			}
				// 		}
				// 	}
				// );
			}
			else {
				console.log("🚀 ~ imageS3Save ~ copyAppendValues:", copyAppendValues)
				handleImageUpload(copyAppendValues[i].base64,copyAppendValues[i].imageName)
				
							setDisable(true)
							if (i === (copyAppendValues.length) - 1) {
								window.location.href = '/patients/patientFiles';
							}
				// await s3.putObject(copyAppendValues[i].capturedData, (err, data) => {
				// 	if (err) {
				// 		toast.error('There was an error uploading your photo: ' + err.message);
				// 	} else {
				// 		setDisable(true)
				// 		if (i === (copyAppendValues.length) - 1) {
				// 			window.location.href = '/patients/patientFiles';
				// 		}
				// 	}
				// })
			}
				
		};
	};
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{load === true ? <Loader /> : ''}
										<div
											class="treatmentPlanCover"
											id="treatmentPlanListDiv"
											style={{ background: '#fff', border: 'none' }}
										>
											<div
												class="treatmentPlanHead"
												style={{ marginBottom: '0px', background: '#ececec' }}
											>
												<span class="treatmentPlanName">IMAGE GALLERY</span>
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}
												<a href=" ">
													<span class="treatmentPlanBtn pl_blue_btn">+ Add</span>
												</a>
											</div>
											{/*  */}
											<div
												class="treatmentImageAdd dentalfilesAddImgDiv_PTour"
												id="divDropImage"
												style={{ margin: '0px' }}
											>
												<select
													name="txtTypeOne"
													class="dentalFilesSelectType_PTour"
													id="txtTypeOne"
													value={type1}
													onChange={onChangeFunction}
												>
													<option value="0">Select Type</option>
													<option value="1">Orthodontic View</option>
													<option value="2">Before &amp; After</option>
													<option value="3">Other</option>
												</select>
												<select
													id="aktest"
													class="dentalFilesSelPosition_PTour"
													name="aktest"
													value={type2}
													onChange={onTypeChange2}
													style={{ display: 'block' }}
												/>
												<select
													id="txtTypeTwo"
													class="dentalFilesSelPosition_PTour"
													name="txtTypeTwo"
													value={type2}
													onChange={onTypeChange2}
													style={{ display: 'none' }}
												>
													{/* <option value="" /> */}
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
												<select
													id="txtTypeThree"
													class="dentalFilesSelPosition_PTour"
													name="txtTypeThree"
													value={type2}
													onChange={onTypeChange2}
													style={{ display: 'none' }}
												>
													{/* <option value="" /> */}
													<option value="Before">Before</option>
													<option value="After">After</option>
												</select>
												<select
													id="txtTypeFour"
													class="dentalFilesSelPosition_PTour"
													name="txtType"
													value={type2}
													onChange={onTypeChange2}
													style={{ display: 'none' }}
												>

													<option value="1">Others</option>
												</select>
												<input
													type="text"
													id="txtfolder0"
													class="dentalFilesFolder_PTour ui-autocomplete-input"
													name="txtfolder0"
													placeholder="Enter FolderName"
													autocomplete="off"
													value={type4}
													onChange={onTypeChange4}
													style={{ marginRight: '35px' }}
												/>
												<div class="treatmentUploadSpltCvr">
													<div class="webCamImageWrap" style={{ float: 'left' }}>
														<div class="btnFileUpload practiceGreenButton dentalFilesUpload_PTour">
															<input
																type="file"
																id="imageName"
																name="imageName"
																onChange={(e) => {
																	handleImageUploadOnChange(e)
																	e.currentTarget.value = null
																}}

															/>
															<label for="imageName" title="Upload" id="uploadButton">
																Upload
															</label>

														</div>
														<span className='uploadCondtion'>Maximum 3MB</span>
													</div>
													<div class="treatmentUploadWeb">
														<div class="webCamWrap dentalFilesTakePhoto_PTour">
															<div class="webCamImg">
																<img src={imagePreview} />
																<div class="webCamImageWrap webCamImageMain">
																	{state.isDataDefault === 1 ? (
																		<WebcamCaptureDental
																			onCapture={onCaptureHandler}
																			webCamImage={webCamImageFunction}
																		/>
																	) : (
																		''
																	)}
																	<input
																		class="practiceGreenButton"
																		type="button"
																		id="imageUpload0"
																		onClick={addWebCam}
																		value="Take Photo"
																		title="Take Photo"
																	/>
																</div>
															</div>
														</div>
													</div>
													<span
														onClick={(e) => addAchievements(e)}
														class="uploadIcon dentalFilesAddMore_PTour"
														style={{ position: 'relative' }}
													>
														<img src={Add} title="Add More" />
													</span>
												</div>
												{/* <img
													title="Close"
													style={{ cursor: 'pointer' }}
													src={Close}
													onClick={(e) => removeRow(e)}
												/> */}
											</div>
											<tbody>
												{addValue.map((item, index) => (
													<div
														class="treatmentImageAdd dentalfilesAddImgDiv_PTour"
														id="divDropImage"
													>
														<select
															name="imageTypeId"
															class="dentalFilesSelectType_PTour"
															id="imageTypeId"
															value={item.imageTypeId}
															onChange={(e) => categoryTypeNewChange(e, index)}
														>
															<option value="0">Select Type</option>
															<option value="1">Orthodontic View</option>
															<option value="2">Before &amp; After</option>
															<option value="3">Other</option>
														</select>
														<select
															id="description"
															class="dentalFilesSelPosition_PTour"
															name="description"
															value={item.description}
															onChange={(e) => categoryTypeNewChange(e, index)}
															style={{
																display: item.imageTypeId === '1' ? 'block' : 'none',
															}}
														>
															<option value="1">1</option>
															<option value="2">2</option>
															<option value="3">3</option>
															<option value="4">4</option>
															<option value="5">5</option>
															<option value="6">6</option>
															<option value="7">7</option>
															<option value="8">8</option>
															<option value="9">9</option>
															<option value="10">10</option>
														</select>
														<select
															id="description"
															class="dentalFilesSelPosition_PTour"
															name="description"
															value={item.description}
															onChange={(e) => categoryTypeNewChange(e, index)}
															style={{
																display: item.imageTypeId === '2' ? 'block' : 'none',
															}}
														>
															<option value="Before">Before</option>
															<option value="After">After</option>
														</select>
														<select
															id="description"
															class="dentalFilesSelPosition_PTour"
															name="description"
															value={item.description}
															onChange={(e) => categoryTypeNewChange(e, index)}
															style={{
																display: item.imageTypeId === '3' ? 'block' : 'none',
															}}
														>
															{/* <option value="0" /> */}
															<option value="1">Other</option>
														</select>
														<input
															type="text"
															id={'folderName' + index}
															class="dentalFilesFolder_PTour ui-autocomplete-input"
															name="folderName"
															placeholder="Enter FolderName"
															autocomplete="off"
															style={{ marginRight: '35px' }}
															value={item.folderName}
															onChange={(e) => categoryTypeNewChange(e, index)}
														/>
														{/* <div class="treatmentUploadSpltCvr">
															<div class="webCamImageWrap" style={{ float: 'left' }}>
																<div class="btnFileUpload practiceGreenButton dentalFilesUpload_PTour">
																	<input
																		type="file"
																		name="imageName"
																		id="imageName"
																		//  onChange={(e) => handleImageUploadOnChange(e,index)}
																		onChange={(e) =>
																			categoryTypeNewChange(e, index)}
																	/>												
																	{item.isData === 1 ? (
																	<label
																		for="imageName"
																		title="Upload"
																		value={item.imageName}
																		id="uploadButton"
																	>
																		Upload
																	</label>
																	) : (
																		""
																	)}

																</div>
															</div>
														</div> */}
														<div class="treatmentUploadWeb">
															<div class="webCamWrap dentalFilesTakePhoto_PTour">
																<div class="webCamImg">
																	<img src={item.base64 ? item.base64 : item.imageNameFile} />
																	<div class="webCamImageWrap webCamImageMain">
																		{item.isData === 1 ? (
																			<WebcamCaptureDental
																				onCapture={onCaptureHandler}
																				webCamImage={webCamImageFunction}
																			/>

																		) : (
																			''
																		)}
																		{/* <input
																			class="practiceGreenButton"
																			type="button"
																			id="imageUpload0"
																			onClick={addWebCam}
																			value="Take Photo"
																			title="Take Photo"
																		/> */}
																	</div>
																</div>
															</div>
														</div>
														<span className="uploadIcon" style={{ position: 'relative' }}>
															<img
																title="Close"
																style={{ cursor: 'pointer' }}
																src={Close}
																onClick={(e) => removeRow(e, index)}
															/>
														</span>
													</div>
												))}
											</tbody>
											<br />
											<br />
											<br />
											{/*  */}
											<div
												class="clinicContentBtn ptFilesAddButton"
												style={{ float: 'right', paddingTop: '10px' }}
											>
												<a
													href="/patients/patientFiles"
													style={{ textDecoration: 'none', color: '#393939' }}
												>
													{' '}
													<span
														type="button"
														value="Cancel"
														className="clinicalCancel dentalFilesCancelBtn"
													>
														Cancel
													</span>
												</a>
												{/* <input
													type="button"
													value="Cancel"
													class="clinicalCancel dentalFilesCancelBtn"
													href="/patients/patientFilesAdd"/> */}
												<input
													type="submit"
													name="_action_saveMemberGallery"
													value="Save"
													id="saveButton"
													class="clinicalSave dentalFilesSaveBtn"
													style={{ display: 'inline - block' }}
													onClick={patientFileSave}
													disabled={disable}
												/>
											</div>
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default PatientFilesAdd;
