
import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Datepicker from 'react-datepicker';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import { callingAPI } from '../config';
import { Link, useLocation } from 'react-router-dom';
import TopArrow from '../images/icons/topArrow.png';
import DownArrow from '../images/icons/downArrow.png';
import Loader from '../Common/loader';
import moment from 'moment';
import TeethSection from './teethSection';
import useStateWithCallback from 'use-state-with-callback';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
function AddTreatmentPlans() {
	const navigate = useNavigate();
	$(document).ready(function () {
		$('#addTreatmentPlans').addClass("testResultAdd");

	})
	const changeAmount = () => {
		costAndQuantityCalculation();
	};
	const saveCSVData = () => {
		if (csvfileData.length > 0) {
			saveCSV();
		}
	};
	const [procedureListBackUp, setprocedureListBackUp] = useState([]);
	const [procedureList, setProcedureList] = useState([]);
	const [procedureSearch, setProcedureSearch] = useState('');
	const [procedureArray, setProcedureArray] = useState([]);
	const [categoryId1, setCategoryId1] = useState('0');
	const [itemName, setItemName] = useState('');
	const [itemQty, setItemQty] = useState('');
	const [treatmentPlans, setTreatmentPlans] = useStateWithCallback([], changeAmount);
	const [doctorList, setDoctorList] = useState([]);
	const [removedIds, setRemovedIds] = useState([]);
	const [newDate, setDate] = useState('');
	const [totalCost, setTotalCost] = useState(0.00);
	const [totalTax, setTotalTax] = useState(0.00);
	const [grandTotal, setGrandTotal] = useState(0.00);
	const [doctorId, setDoctorId] = useState(window.sessionStorage.getItem('patientDoctorId'));
	const [doctorName, setDoctor] = useState(window.sessionStorage.getItem('patientDoctorName'));
	const [medicineBranchId, setmedicineBranchId] = useState(window.sessionStorage.getItem('medicineBranchId'));
	const [hidMemberProcedureDentalOrderId, setHidMemberProcedureDentalOrderId] = useState('');
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	const [isData, setIsData] = useState(false);
	const location = useLocation();
	const [csvfileData, setFile] = useStateWithCallback('', saveCSVData);
	const [procedureCategoryValues, setProcedureCategoryValues] = useState([]);
	const [procedureCategoryId, setProcedureCategoryId] = useState("");
	const [procedureCategoryIdSearch, setProcedureCategoryIdSearch] = useState("");

	const [procedureData, setProcedureData] = useState({
		procedureName: '',
		procedureCode: '',
		procedureAmount: '',
		chkShowTeeth: 0,
		chkMultipleCost: 0,
		chkConcentForm: 0,
	});
	const onChange = (event) => {
		const { name, value } = event.target;
		setProcedureData((prevState) => ({ ...prevState, [name]: value }));
	};
	const onChangeCategory = (event, i) => {
		const { name, value } = event.target;
		const values = [...procedureArray];
		values[i] = { ...values[i], [name]: value };
		setProcedureArray(values);
	};
	const onChangeCheckBox = (event) => {
		const { name, checked } = event.target;
		setProcedureData((prevState) => ({ ...prevState, [name]: checked }));
	};
	const disableQuantity = (event, j, mode) => {
		const { name, value } = event.target;
		const values = [...treatmentPlans];
		values[j] = {
			...values[j],
			[name]: 1,
			memberProcedureDentalDetailsQuantity: 1
		};
		setTreatmentPlans([...values]);
	}
	const onChangeQuantity = async (event, j, mode) => {
		const { name, value } = event.target;
		const values = [...treatmentPlans];
		let currentQty = 0;
		let currentCost = 0;
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (event.target.value === '' || re.test(event.target.value)) {
			if (name === 'memberProcedureDentalDetailsQuantity') {
				currentQty = value;
				currentCost = values[j].memberProcedureDentalDetailsTreatmentCost;
			} else {
				currentQty = values[j].memberProcedureDentalDetailsQuantity;
				currentCost = value;
			}
			values[j] = {
				...values[j],
				[name]: value,
				memberProcedureDentalDetailsGrandIndividual: (currentCost * currentQty),
				memberProcedureDentalDetailsQuantity: currentQty,
				memberProcedureDentalDetailsTreatmentCost: currentCost,

			};
		} else {

			if (name === 'memberProcedureDentalDetailsQuantity') {
				currentQty = 1;
				currentCost = values[j].memberProcedureDentalDetailsTreatmentCost;
			} else {
				toast.error('Only Numbers Accepted')
				currentQty = values[j].memberProcedureDentalDetailsQuantity;
				currentCost = values[j].memberProcedureDentalDetailsTreatmentCost;

			}
			values[j] = {
				...values[j],
				[name]: value,
				memberProcedureDentalDetailsGrandIndividual: (currentCost * currentQty),
				memberProcedureDentalDetailsQuantity: currentQty,
				memberProcedureDentalDetailsTreatmentCost: currentCost,

			};
		}
		setTreatmentPlans([...values]);
		getTaxDetailsWithoutAsync(values[j].pmHospitalTreatmentDetailsId, (currentQty * currentCost), j, currentQty, currentCost, values[j].taxDetails)
	};
	const costAndQuantityCalculation = () => {
		var totalCost1 = 0;
		var totalTax1 = 0.00
		var grand = 0.00
		for (var i = 0; i < treatmentPlans.length; i++) {
			var quantity = treatmentPlans[i].memberProcedureDentalDetailsQuantity;
			var cost = treatmentPlans[i].memberProcedureDentalDetailsTreatmentCost;
			var tax = treatmentPlans[i].memberProcedureDentalDetailsTaxIndividual;
			setTotalTax(tax)
			totalCost1 = totalCost1 + (quantity * cost);
			totalTax1 = totalTax1 + tax
			grand = +totalCost1 + +totalTax1
			if (
				treatmentPlans[i].showTeeth === 1 &&
				treatmentPlans[i].memberProcedureDentalDetailsTeethNumber !== '' &&
				treatmentPlans[i].memberProcedureDentalDetailsTeethNumber !== 'None Selected' &&
				treatmentPlans[i].memberProcedureDentalDetailsTeethNumber !== 'Full Mouth'
			) {
				showTeethListing(i, '1');
				var teethData = treatmentPlans[i].memberProcedureDentalDetailsTeethNumber.toString().split('|');
				for (let index = 0; index < teethData.length; index++) {
					var element = document.getElementsByClassName('teethImg' + teethData[index] + i);
					if (element.length > 0) {
						element[0].classList.add('teethActive');
					}
				}
			} else if (treatmentPlans[i].memberProcedureDentalDetailsTeethNumber === 'Full Mouth') {
				showTeethListing(i, '1');
				var element = document.getElementsByClassName('teethImg' + i);
				for (let index = 0; index < element.length; index++) {
					element[index].classList.add('teethActive');
				}
			}
			if (
				treatmentPlans[i].memberProcedureDentalDetailsNotes !== '' &&
				treatmentPlans[i].memberProcedureDentalDetailsNotes !== null &&
				treatmentPlans[i].memberProcedureDentalDetailsNotes !== 'null'
			) {
				document.getElementById('addNote' + i).style.display = 'block';
				document.getElementById('addNoteLabel' + i).style.display = 'none';
			}
		}
		setTotalTax(totalTax1);
		setTotalCost(totalCost1);
		setGrandTotal(grand);
	};
	const onChangeAddNotes = (event, i) => {
		const { name, value } = event.target;
		const values = [...treatmentPlans];
		values[i] = { ...values[i], [name]: value };
		setTreatmentPlans(values);
	};
	const setTeethInfo = (data, index) => {
		if (data === 'Multiply Cost') {
			if (treatmentPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth === 1) {
				treatmentPlans[index].memberProcedureDentalDetailsQuantity = 1
				treatmentPlans[index].memberProcedureDentalDetailsGrandIndividual = treatmentPlans[index].memberProcedureDentalDetailsTreatmentCost
			}
			else {
				treatmentPlans[index].memberProcedureDentalDetailsQuantity = treatmentPlans[index].memberProcedureDentalDetailsTeethNumber.toString().split('|').length
				treatmentPlans[index].memberProcedureDentalDetailsGrandIndividual = treatmentPlans[index].memberProcedureDentalDetailsQuantity * treatmentPlans[index].memberProcedureDentalDetailsTreatmentCost
			}
			treatmentPlans[index].fullMouth = 0;
			treatmentPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth = treatmentPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth === 1 ? 0 : 1;
		} else if (data === 'Full Mouth' || data === 'None Selected') {
			if (data === 'Full Mouth') {
				if (treatmentPlans[index].fullMouth === 1) {
					var element = document.getElementsByClassName('teethImg' + index);
					for (var i = 0; i < element.length; i++) {
						if (treatmentPlans[index].fullMouth === 0) {
							element[i].classList.add('teethActive');
						} else {
							element[i].classList.remove('teethActive');
						}
					}
				}
				treatmentPlans[index].memberProcedureDentalDetailsTeethNumber =
					treatmentPlans[index].fullMouth === 1 ? 'None Selected' : 'Full Mouth';
				treatmentPlans[index].fullMouth = treatmentPlans[index].fullMouth === 1 ? 0 : 1;
				treatmentPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth = 0;
				treatmentPlans[index].memberProcedureDentalDetailsQuantity = 1;
				treatmentPlans[index].memberProcedureDentalDetailsGrandIndividual = treatmentPlans[index].memberProcedureDentalDetailsQuantity *
					treatmentPlans[index].memberProcedureDentalDetailsTreatmentCost
			} else {
				treatmentPlans[index].memberProcedureDentalDetailsTeethNumber = data === 'null' ? '' : data;
			}
		} else {
			if (treatmentPlans[index].memberProcedureDentalDetailsMultipleCostWithTeeth === 1) {
				treatmentPlans[index].memberProcedureDentalDetailsQuantity = data.toString().split('|').length;
				treatmentPlans[index].memberProcedureDentalDetailsGrandIndividual = data.toString().split('|').length * treatmentPlans[index].memberProcedureDentalDetailsTreatmentCost;
			}
			treatmentPlans[index].memberProcedureDentalDetailsTeethNumber = data === 'null' ? '' : data;
		}
		setTreatmentPlans([...treatmentPlans]);
		getTaxDetailsWithoutAsync(treatmentPlans[index].pmHospitalTreatmentDetailsId, (treatmentPlans[index].memberProcedureDentalDetailsQuantity * treatmentPlans[index].memberProcedureDentalDetailsTreatmentCost), index, treatmentPlans[index].memberProcedureDentalDetailsQuantity, treatmentPlans[index].memberProcedureDentalDetailsTreatmentCost, treatmentPlans[index].taxDetails)
	};
	const procedureCategoryChange = (event) => {
		setProcedureCategoryId(event.target.value);
	};

	// useEffect(async () => {
	// 	selectBranchList();
	// 	if (location.state && Object.keys(location.state).length !== 0) {
	// 		const { dentalOrderId } = location.state;
	// 		const postdata = {
	// 			hospitalId: localStorage.getItem('hospitalId'),
	// 			memberId: window.sessionStorage.getItem('memberId'),
	// 			doctorId: doctorId,
	// 			memberProcedureDentalOrderId: dentalOrderId,
	// 		};
	// 		callingAPI('treatmentPlanDetails/treatmentListing', postdata).then(async (data) => {
	// 			if (data.data.success === '1') {
	// 				var array = data.data.result[0].itemArray[0].item;
	// 				var date1 = new Date(array[0].dateOn);
	// 				setDate(date1);
	// 				var totalCostValue = '0.00';
	// 				var dentalOrderId = '';
	// 				var totalGrand = '0.00';
	// 				var taxTotalget = 0.00
	// 				var netTotal = '0.00'
	// 				var treatmentArray = [];
	// 				for (var i = 0; array.length > i; i++) {
	// 					var taxDetails = await getTaxDetails(array[i].hidProcedureId, array[i].quantity * array[i].treatmentCost);
	// 					let fm = 0;
	// 					let mc = array[i].chkMultipleCost;
	// 					if (array[i].hidTeethNumber === 'Full Mouth') {
	// 						fm = 1;
	// 						mc = 0;
	// 					}
	// 					var a = {
	// 						pmHospitalTreatmentDetailsId: array[i].hidProcedureId,
	// 						memberProcedureDentalDetailsId: array[i].hidMemberProcedureDentalDetailsId,
	// 						memberProcedureDentalDetailsTaxIndividual: array[i].hidTaxIndividual,
	// 						memberProcedureDentalDetailsTreatmentCost: array[i].treatmentCost,
	// 						memberProcedureDentalDetailsDiscountIndividual: '0',
	// 						memberProcedureDentalDetailsGrandIndividual: (array[i].quantity * array[i].treatmentCost) + array[i].hidTaxIndividual,
	// 						memberProcedureDentalDetailsNotes: array[i].notes,
	// 						memberProcedureDentalDetailsQuantity: array[i].quantity,
	// 						memberProcedureDentalDetailsTeethNumber:
	// 							array[i].hidTeethNumber === 'null' ? '' : array[i].hidTeethNumber,
	// 						memberProcedureDentalDetailsDiscountType: array[i].discountType,
	// 						memberProcedureDentalDetailsDiscountAmount: array[i].discountAmount,
	// 						memberProcedureDentalDetailsMultipleCostWithTeeth: mc,
	// 						treatmentCode: array[i].procedureCode,
	// 						treatmentName: array[i].procedureName,
	// 						fullMouth: fm,
	// 						isConsentForm: array[i].isConsentForm,
	// 						showTeeth: array[i].showTeeth,
	// 						isPaymentCompleted: array[i].isPaymentCompleted,
	// 						taxDetails: taxDetails.taxDetails
	// 					};
	// 					treatmentArray.push(a);
	// 					taxTotalget = array[0].hidTotalTax
	// 					totalCostValue = +totalCost + +array[0].hidCostTotal;
	// 					totalGrand = +grandTotal + +array[0].hidGrandTotal;
	// 					dentalOrderId = array[0].hidMemberProcedureDentalOrderId;
	// 				}
	// 				setDoctor(array[0].doctorName);
	// 				setDoctorId(array[0].doctorId)
	// 				setTreatmentPlans([...treatmentArray]);
	// 				setHidMemberProcedureDentalOrderId(dentalOrderId);
	// 				setTotalTax(taxTotalget)
	// 				setTotalCost(totalCostValue);
	// 				setGrandTotal(totalGrand);
	// 			}
	// 		});
	// 	}
	// 	procedureListing();
	// }, []);
	useEffect(() => {
		const fetchData = async () => {
			await selectBranchList();
			if (location.state && Object.keys(location.state).length !== 0) {
				const { dentalOrderId } = location.state;
				const postdata = {
					hospitalId: localStorage.getItem('hospitalId'),
					memberId: window.sessionStorage.getItem('memberId'),
					doctorId: doctorId,
					memberProcedureDentalOrderId: dentalOrderId,
				};
				const data = await callingAPI('treatmentPlanDetails/treatmentListing', postdata);
				if (data.data.success === '1') {
					const array = data.data.result[0].itemArray[0].item;
					const date1 = new Date(array[0].dateOn);
					setDate(date1);

					let totalCostValue = '0.00';
					let dentalOrderId = '';
					let totalGrand = '0.00';
					let taxTotalget = 0.00;
					let netTotal = '0.00';
					let treatmentArray = [];

					for (let i = 0; i < array.length; i++) {
						const taxDetails = await getTaxDetails(array[i].hidProcedureId, array[i].quantity * array[i].treatmentCost);
						let fm = 0;
						let mc = array[i].chkMultipleCost;

						if (array[i].hidTeethNumber === 'Full Mouth') {
							fm = 1;
							mc = 0;
						}

						const a = {
							pmHospitalTreatmentDetailsId: array[i].hidProcedureId,
							memberProcedureDentalDetailsId: array[i].hidMemberProcedureDentalDetailsId,
							memberProcedureDentalDetailsTaxIndividual: array[i].hidTaxIndividual,
							memberProcedureDentalDetailsTreatmentCost: array[i].treatmentCost,
							memberProcedureDentalDetailsDiscountIndividual: '0',
							memberProcedureDentalDetailsGrandIndividual: (array[i].quantity * array[i].treatmentCost) + array[i].hidTaxIndividual,
							memberProcedureDentalDetailsNotes: array[i].notes,
							memberProcedureDentalDetailsQuantity: array[i].quantity,
							memberProcedureDentalDetailsTeethNumber: array[i].hidTeethNumber === 'null' ? '' : array[i].hidTeethNumber,
							memberProcedureDentalDetailsDiscountType: array[i].discountType,
							memberProcedureDentalDetailsDiscountAmount: array[i].discountAmount,
							memberProcedureDentalDetailsMultipleCostWithTeeth: mc,
							treatmentCode: array[i].procedureCode,
							treatmentName: array[i].procedureName,
							fullMouth: fm,
							isConsentForm: array[i].isConsentForm,
							showTeeth: array[i].showTeeth,
							isPaymentCompleted: array[i].isPaymentCompleted,
							taxDetails: taxDetails.taxDetails
						};

						treatmentArray.push(a);
						taxTotalget = array[0].hidTotalTax;
						totalCostValue = +totalCost + +array[0].hidCostTotal;
						totalGrand = +grandTotal + +array[0].hidGrandTotal;
						dentalOrderId = array[0].hidMemberProcedureDentalOrderId;
					}

					setDoctor(array[0].doctorName);
					setDoctorId(array[0].doctorId);
					setTreatmentPlans([...treatmentArray]);
					setHidMemberProcedureDentalOrderId(dentalOrderId);
					setTotalTax(taxTotalget);
					setTotalCost(totalCostValue);
					setGrandTotal(totalGrand);
				}
			}
			procedureListing();
		};

		fetchData();
	}, []);



	const selectBranchList = () => {
		const PostData = {};
		callingAPI('commonUserFunctions/getTreatmentCategoryList', PostData)
			.then((data) => {
				if (data.data.success === '1') {

					setProcedureCategoryValues(data.data.result.categoryListArray);
				}
				else {
					setProcedureCategoryValues([]);
				}

			})
	}
	const procedureListing = (procedureSearch) => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			searchValue: procedureSearch === 'undefined' ? '' : procedureSearch,
			tabValue: 'Procedures',
			medicineBranchId: window.sessionStorage.getItem("siteBranchId") === "4" ? (window.sessionStorage.getItem('medicineBranchId')) : "",
		};
		callingAPI('treatmentPlanDetails/procedureListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				console.log("data.data.result.resultData", data.data.result.resultData)
				setprocedureListBackUp(data.data.result.resultData);
				setProcedureList(data.data.result.resultData);
				setIsData(true);
			} else {
				setprocedureListBackUp(data.data.result.resultData)
				setProcedureList(data.data.result.resultData);
				setIsData(true);
			}
		});
	};

	// const searchProcedure = (event) => {
	// 	const { value } = event.target;
	// 	setProcedureSearch(value);
	// 	if (value.trim() !== "") {
	// 		const filteredData = procedureListBackUp.filter((item) => {
	// 			const procedureName = Object.values(item.procedureName).join('').toLowerCase();
	// 			const procedureCode = Object.values(item.procedureCode).join('').toLowerCase();
	// 			const branchName = Object.values(item?.pmTreatmentsBranchName ? item?.pmTreatmentsBranchName : "").join('').toLowerCase();
	// 			const searchValue = value.toLowerCase();
	// 			return procedureName.includes(searchValue) || procedureCode.includes(searchValue) || branchName.includes(searchValue);
	// 		});
	// 		setProcedureList(filteredData);
	// 	} else {
	// 		setProcedureList([...procedureListBackUp]);
	// 	}
	// };


	const procedureCategoryIdSearchChange = (event) => {
		const { value } = event.target;
		setProcedureCategoryIdSearch(value);

		if (value.trim() !== "") {
			// Filter by the selected branch ID
			const filteredData = procedureListBackUp.filter((item) =>
				item.pmTreatmentsBranchId === parseInt(value)
			);
			setProcedureList(filteredData); // Set the filtered list
		} else {
			setProcedureList([...procedureListBackUp]); // Reset to the full list if no branch is selected
		}

		setProcedureSearch(""); // Clear the search text when a new branch is selected
	};

	const searchProcedure = (event) => {
		const { value } = event.target;
		setProcedureSearch(value);

		// Determine the current list to search within (filtered by branch or the full backup list)
		const currentList = procedureCategoryIdSearch ? procedureListBackUp.filter((item) =>
			item.pmTreatmentsBranchId === parseInt(procedureCategoryIdSearch)
		) : procedureListBackUp;

		if (value.trim() !== "") {
			const filteredData = currentList.filter((item) => {
				const procedureName = item.procedureName.toLowerCase();
				const procedureCode = item.procedureCode.toLowerCase();
				const branchName = item.pmTreatmentsBranchName ? item.pmTreatmentsBranchName.toLowerCase() : '';
				const searchValue = value.toLowerCase();

				return procedureName.includes(searchValue) || procedureCode.includes(searchValue) || branchName.includes(searchValue);
			});
			setProcedureList(filteredData); // Set the search-filtered list
		} else {
			// If the search is cleared, revert to the branch-filtered list or the full list
			setProcedureList([...currentList]);
		}
	};


	const addProcedure = () => {
		document.getElementById('addProNew').style.display = 'block';
		document.getElementById('addPro').style.display = 'none';
		document.getElementById('closePro').style.display = 'block';
		setProcedureData({
			procedureName: '',
			procedureCode: '',
			procedureAmount: '',
			chkShowTeeth: 0,
			chkMultipleCost: 0,
			chkConcentForm: 0,
		});
		setCategoryId1('0');
		setItemName('');
		setItemQty('');
		setProcedureArray([]);
	};
	const closeProcedure = () => {
		document.getElementById('closePro').style.display = 'none';
		document.getElementById('addPro').style.display = 'block';
		document.getElementById('addProNew').style.display = 'none';
	};
	const uploadProcedureCSV = (e) => {
		document.getElementById('uploadProcedureCSVFile').click();

	};
	const uploadProcedureCSVFile = (e) => {
		setIsData(false);
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);
				if (typeof workbook.Sheets[sheetName].A1 !== "undefined" && typeof workbook.Sheets[sheetName].B1 !== "undefined" && typeof workbook.Sheets[sheetName].C1 !== "undefined" && typeof workbook.Sheets[sheetName].D1 !== "undefined") {
					if (workbook.Sheets[sheetName].A1.v !== "ProcedureName" || workbook.Sheets[sheetName].B1.v !== "ProcedureCode" || workbook.Sheets[sheetName].C1.v !== "BranchName" || workbook.Sheets[sheetName].D1.v !== "Amount") {
						toast.error("Please upload a valid CSV file.")
						document.getElementById("uploadProcedureCSVFile").value = ""
						setIsData(true);
					} else if (json.length > 0) {
						var ProcedureFlag = 0
						for (let i = 0; i < json.length; i++) {

							if (json[i].ProcedureName == undefined) {
								ProcedureFlag = ProcedureFlag + 1
							}
						}
						if (json.length == ProcedureFlag) {
							toast.error("Atlest Add One Procedure Name")
							setIsData(true);
							document.getElementById("uploadProcedureCSVFile").value = ""
						}
						else {
							setFile(json);
						}

					} else {
						toast.error("Procedure Name required")
						setIsData(true);
					}
				} else {
					toast.error("Please upload a valid CSV file.")
					document.getElementById("uploadProcedureCSVFile").value = ""
					setIsData(true);
				}
			};
			reader.readAsArrayBuffer(e.target.files[0]);

		}

	};

	const saveCSV = () => {
		var saveformat = [];
		for (var i = 0; i < csvfileData.length; i++) {
			if (csvfileData[i].ProcedureName !== undefined && csvfileData[i].ProcedureName !== null) {
				var CsvsaveForm = {
					pmTreatmentsMedicalSpeciality: medicineBranchId === '4' ? 'Dentistry' : 'Other',
					pmHospitalTreatmentDetailsProcedureCode: csvfileData[i].ProcedureCode === undefined ? "" : csvfileData[i].ProcedureCode.toString(),
					pmTreatmentsName: csvfileData[i].ProcedureName,
					pmHospitalTreatmentDetailsPrice: csvfileData[i].Amount,
					pmHospitalTreatmentDetailsBranchName: csvfileData[i].BranchName,
					pmHospitalTreatmentDetailsMultiplyCostWithTeeth: false,
					pmHospitalTreatmentDetailsShowTeeth: false,
					pmHospitalTreatmentDetailsConsentForm: false,
					pmHospitalTreatmentDetailsDefaultNotes: '',
					taxDetails: '',
					categoryData: [],
				};
				saveformat.push(CsvsaveForm);
			}
		}
		if (csvfileData.length !== '') {
			let PostData = {
				hospitalId: localStorage.getItem('hospitalId'),
				procedureList: saveformat,
			};
			callingAPI('commonUserFunctions/procedureBulkUpload', PostData).then((response) => {
				if (response.data.success === '1') {
					toast.success('File successfully uploaded');
					window.location.reload()
					setFile('');
					procedureListing();
					setIsData(true);
				} else {
					toast.error("The file was not uploaded successfully")
					//procedureListing();
					window.location.reload()
					setIsData(true);
				}
			});
		}
	};
	const procedureSave = () => {

		setIsData(false);
		let flag = true;
		let copyAppendValues = [...procedureArray];
		if (!procedureData.procedureName) {
			toast.error("Procedure Name required")
			document.getElementById('procedureName').focus();
			flag = false;
			setIsData(true);
		}
		else if (!procedureData.procedureCode) {
			toast.error(" Procedure Code required")
			document.getElementById('procedureCode').focus();
			flag = false;
			setIsData(true);
		}
		else if (!procedureData.procedureAmount) {
			toast.error("Procedure Amount required")
			document.getElementById('procedureAmount').focus();
			flag = false;
			setIsData(true);
		}
		else if (categoryId1 !== '0' && itemName !== '' && itemQty !== '') {

			var b = []
			b = copyAppendValues
			var filteredArr = b.filter(f =>
				f.categoryId === document.getElementById('categoryId1').value &&
				f.itemName === document.getElementById('itemName').value
			)
			if (filteredArr.length > 0) {
				toast.error("Please remove duplicate inventory data")
				setIsData(true);
				flag = false;
			}
			if (flag === true) {
				var a = {
					categoryId: categoryId1,
					itemName: itemName,
					itemQty: itemQty,
				};
				copyAppendValues.push(a);
				flag = true;
			}

		}
		for (var i = 0; i < procedureList.length; i++) {
			var name = procedureList[i].procedureName
			var code = procedureList[i].procedureCode
			if (procedureData.procedureName === name && procedureData.procedureCode === code) {
				toast.error("Procedure Already Exist")
				setIsData(true);
				flag = false
				break
			}
		}


		if (flag === true) {
			const apiJson = {
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				pmTreatmentsName: procedureData.procedureName,
				procedureCode: procedureData.procedureCode,
				hospitalId: localStorage.getItem('hospitalId'),
				chkShowTeeth: medicineBranchId === '4' ? (procedureData.chkShowTeeth === true ? 1 : 0) : 0,
				chkMultipleCost: medicineBranchId === '4' ? (procedureData.chkMultipleCost === true ? 1 : 0) : 0,
				chkConcentForm: medicineBranchId === '4' ? (procedureData.chkConcentForm === true ? 1 : 0) : 0,
				proCost: procedureData.procedureAmount,
				item: copyAppendValues,
				treatmentBranchId: window.sessionStorage.getItem("siteBranchId") === "4" ? procedureCategoryId : ""
				// treatmentBranchId: procedureCategoryId
			};
			callingAPI('commonUserFunctions/saveProcedureAuto', apiJson).then((data) => {
				setIsData(true);
				if (data.data.success === '1') {
					toast.success('Procedure added successfully');
					procedureListing();
					document.getElementById('addProNew').style.display = 'none';
					document.getElementById('closePro').style.display = 'none';
					document.getElementById('addPro').style.display = 'block';
				} else {
					toast.error(data.data.errorMessage);
				}
			});
		}
	};
	const addNotes = (i) => {
		document.getElementById('addNote' + i).style.display = 'block';
		document.getElementById('addNoteLabel' + i).style.display = 'none';
		document.getElementById('addNote' + i).focus()
	};
	const addNewProcedure = async (procedureObj, i, id) => {
		setDate(new Date());
		var taxDetails = await getTaxDetails(procedureObj.procedureId, procedureObj.price);
		var taxTotal = taxDetails.taxAmountTotal;
		var taxTot = taxTotal + totalTax
		setTotalTax(taxTot);
		var totalCostValue = +totalCost + +procedureObj.price;
		var totalGrand = +(+grandTotal + +procedureObj.price) + +(taxTotal !== 0.00 ? taxTotal : 0.00);

		setTotalCost(totalCostValue);
		//	setTotalTax(taxTotal);
		setGrandTotal(totalGrand);
		var arrayObj = {
			pmHospitalTreatmentDetailsId: procedureObj.procedureId,
			memberProcedureDentalDetailsId: '',
			memberProcedureDentalDetailsTaxIndividual: (taxTotal !== 0.00 ? taxTotal : 0.00),
			memberProcedureDentalDetailsTreatmentCost: procedureObj.price,
			memberProcedureDentalDetailsDiscountIndividual: '0.00',
			memberProcedureDentalDetailsGrandIndividual: procedureObj.price + (taxTotal !== 0.00 ? taxTotal : 0.00),
			memberProcedureDentalDetailsNotes: procedureObj.defaultNotes,
			memberProcedureDentalDetailsQuantity: '1',
			memberProcedureDentalDetailsTeethNumber: 'None Selected',
			memberProcedureDentalDetailsDiscountType: 'INR',
			memberProcedureDentalDetailsDiscountAmount: '0.00',
			memberProcedureDentalDetailsMultipleCostWithTeeth: procedureObj.multiplyCostWithTeeth,
			treatmentCode: procedureObj.procedureCode,
			treatmentName: procedureObj.procedureName,
			fullMouth: 0,
			isConsentForm: procedureObj.isConsentForm,
			showTeeth: procedureObj.showTeeth,
			isPaymentCompleted: '0',
			taxDetails: taxDetails.taxDetails
		};
		setTreatmentPlans([...treatmentPlans, arrayObj]);
	};
	const removeTreatmentPlan = (i, obj, id) => {
		document.getElementById('lblMessage').style.display = 'inline-block';
		let removed = [...treatmentPlans];
		removed.splice(i, 1);
		setTreatmentPlans(removed);
		var taxTot = +totalTax + -obj.memberProcedureDentalDetailsTaxIndividual;
		var cost = +totalCost + -obj.memberProcedureDentalDetailsTreatmentCost;
		var grand = +grandTotal + -obj.memberProcedureDentalDetailsGrandIndividual;
		var totGrand = grand - obj.memberProcedureDentalDetailsTaxIndividual
		setTotalTax(taxTot);
		setTotalCost(cost);
		setGrandTotal(totGrand);
		removeMessage();
		removedIds.push(obj.memberProcedureDentalDetailsId)

	};
	const getTaxDetails = async (id, amount) => {
		return new Promise((resolve, reject) => {
			const poastData = {
				"procedureId": id,
				"amount": amount
			};
			callingAPI('commonUserFunctions/getTaxDetails', poastData).then((data) => {
				if (data.data.success === '1') {
					var a = {
						taxDetails: data.data.result.taxDetails,
						taxAmountTotal: data.data.result.taxAmountTotal
					}
					resolve(a);
				} else {
					resolve([]);
				}
			});
		});
	};

	const getTaxDetailsWithoutAsync = (id, amount, index, currentQty, currentCost, taxDetails) => {

		const values = [...treatmentPlans];
		let taxamount = 0
		let taxName = ""
		let taxAmountData = 0
		let taxAmountTot = 0
		var taxDetail = []
		for (var j = 0; j < taxDetails.length; j++) {

			taxamount = (parseFloat(amount) / 100) * (parseFloat(taxDetails[j].taxPercentage))
			taxName = taxDetails[j].taxName
			taxAmountData = parseFloat(taxamount)
			taxAmountTot = parseFloat(taxAmountTot) + parseFloat(taxamount)
			let taxData = {
				taxName: taxName,
				taxAmountData: taxAmountData,
				taxPercentage: taxDetails[j].taxPercentage
			}

			taxDetail.push(taxData)

		}
		taxDetails.push(taxDetail)
		values[index] = {
			...values[index],
			taxDetails: taxDetail,
			memberProcedureDentalDetailsGrandIndividual: amount + taxAmountTot,
			memberProcedureDentalDetailsTaxIndividual: taxAmountTot,
			memberProcedureDentalDetailsQuantity: currentQty,
			memberProcedureDentalDetailsTreatmentCost: currentCost

		};
		setTreatmentPlans([...values]);

	};

	const removeMessage = () => {
		setTimeout(() => {
			document.getElementById('lblMessage').style.display = 'none';
		}, 2000);
	};
	const addRow = (e, i) => {
		let flags = true;
		if (categoryId1 === '0') {
			toast.error('Select Category');
			document.getElementById('categoryId1').focus();
			flags = false;
		} else if (!itemName) {
			toast.error('Enter Item Name ');
			document.getElementById('itemName').focus();
			flags = false;
		} else if (!itemQty) {
			toast.error('Enter Item Quantity ');
			document.getElementById('itemQty').focus();
			flags = false;
		}
		var b = []
		let copyAppendValues = [...procedureArray];
		b = copyAppendValues
		var filteredArr = b.filter(f =>
			f.categoryId === document.getElementById('categoryId1').value &&
			f.itemName === document.getElementById('itemName').value
		)
		if (filteredArr.length > 0) {
			toast.error("Please remove duplicate inventory data")
			setIsData(true);
			flags = false;
		}
		if (flags === true) {
			var a = {
				categoryId: categoryId1,
				itemName: itemName,
				itemQty: itemQty,
			};
			setProcedureArray([...procedureArray, a]);
			setCategoryId1('0');
			setItemName('');
			setItemQty('');
		}
	};
	const removeRow = (e, i) => {
		let copyAppendValues = [...procedureArray];
		copyAppendValues.splice(i, 1);
		setProcedureArray(copyAppendValues);
	};
	const doctorDropdown = () => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			selectCombo: '',
			inputSearch: '',
			max: 100,
			offset: 0,
		};
		document.getElementById('doctorListDiv').style.display = 'block';
		callingAPI('commonUserFunctions/doctorsList', apiJson).then((data) => {
			if (data.data.success === '1') {
				setDoctorList(data.data.result.doctorsListArray);
			}
			else {
				toast.error("no doctors found")
			}
		});
	};
	const showTaxDetails = (i, divId) => {
		document.getElementById("taxListDiv" + i).style.display =
			document.getElementById("taxListDiv" + i).style.display === 'block' ? 'none' : 'block';
		droplist(divId, i)
	}
	const droplist = (divId, i) => {
		var elementDisplay = document.getElementById(divId + i);
		elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divId + i,
			count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
		})
		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	const setDoctorName = (doctorObj, i) => {
		setDoctor(doctorObj.doctorName);
		setDoctorId(doctorObj.doctorId);
		document.getElementById('doctorListDiv').style.display = 'none';
	};
	const numberValidation = (e) => {
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (e.target.value === '' || re.test(e.target.value)) {
			setProcedureData((prevState) => ({ ...prevState, procedureAmount: e.target.value }));
		}
	};
	const itemQtyValidation = (e) => {
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			setItemQty(e.target.value);
		}
	};
	const selectDatePicker = () => {
		document.getElementById('treatmentDateTimeSpan').style.display = 'block';
	};
	const treatmentPlanSave = () => {
		//	setIsData(false);
		document.getElementById('treatmentPlanSaveButton').style.display = 'none';
		var date = moment(newDate).format('yyyy-MM-DD h:mm A');
		var milliStart = moment(date).valueOf();
		var milliEnd = moment(newDate).add(30, 'm').format('yyyy-MM-DD h:mm A');
		const apiJson = {

			userId: window.sessionStorage.getItem('userId'),
			hospitalId: localStorage.getItem('hospitalId'),
			prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,

			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: doctorId,
			scheduledtimeSlotStartTime: milliStart,
			scheduledtimeSlotEndTime: moment(milliEnd).valueOf(),
			appointmentDate: date,
			memberProcedureDentalOrderId: hidMemberProcedureDentalOrderId,
			memberProcedureDentalOrderTaxTotal: totalTax,
			memberProcedureDentalOrderCostTotal: totalCost,
			memberProcedureDentalOrderDiscountTotal: '0',
			memberProcedureDentalOrderGrandTotal: grandTotal,
			pmHospitalTreatmentDetails: treatmentPlans,
			hidRemoveIds: removedIds.toString().replace(/[\[\]']+/g, '')
		};
		callingAPI('commonUserFunctions/saveOrUpdateDentalTreatmentProcedureNew', apiJson).then((data) => {
			//setIsData(true);
			if (data.data.success === '1') {
				toast.success('Procedure Saved Successfully');
				setTimeout(() => {
					// window.location.href = '/patients/treatmentPlans';
					navigate('/patients/treatmentPlans');
				}, 1000)
				//setIsData(true);
			} else {
				toast.error(data.data.errorMessage);
				//setIsData(true);
			}
		});
	};
	const showTeethListing = (i, mode) => {
		//window.location.href="#showTeethDiv" + i ;
		if (mode !== '1') {
			//document.getElementById("paediatricTeethDiv"+i).focus()
			document.getElementById('showTeethDiv' + i).style.display =
				document.getElementById('showTeethDiv' + i).style.display === 'block' ? 'none' : 'block';
		}
		document.getElementById('showTeeth' + i).style.display = 'none';
		document.getElementById('txtTeeth' + i).style.display = 'block';
	};

	function closeNav() {
		if (document.getElementById('doctorListDiv') !== null) {
			document.getElementById('doctorListDiv').style.display = 'none';
		}
	}

	document.onclick = function (e) {
		if (e.target.offsetParent !== null) {
			if (e.target.offsetParent.id !== 'doctorListSection') {
				closeNav();
			}
		}
	};
	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div className="contentWraper contentWraperScroll add_treatment_plan aDDTreat">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection pl_addTreatSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour" style={{ border: "1px solid #ddd", borderTop: "none" }}>
									<div id="divPatientContent" class="sideMenuContent" style={{ borderTop: '1px solid rgb(221, 221, 221)' }}>
										{isData === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead" style={{ border: 'none', padding: '13px 10px 0px' }}
											>
												<span class="treatmentPlanName">TREATMENT PLANS</span>
												<label
													id="lblMessage"
													style={{ color: 'red', marginLeft: '50px', display: 'none' }}
												>
													Successfully removed from your treatment plans
												</label>
												<label />
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}
												{treatmentPlans.length > 0 ? (
													<span
														id="treatmentPlanSaveButton"
														class="treatmentPlanBtn pl_blue_btn"
														style={{ background: '#5bb75b', padding: '4px 15px', display: "block" }}
														onClick={treatmentPlanSave}
													>
														Save
													</span>
												) : (
													''
												)}
												<a href="/patients/treatmentPlans">
													<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
														Cancel
													</span>
												</a>
											</div>
											<div class="treatmentPlanList pl_treatmentDiv pl_addTreatmnt">
												<div class="pl_treat treatmentTable planActive">
													<table style={{ border: 'none' }}>
														<thead>
															<tr className="addTreatmentTr">
																<th class="col-md-3">
																	<div class="dentalProName">TREATMENTS</div>
																</th>
																<th class="col-md-4 " style={{ display: 'flex' }}>
																	<div
																		class="dentalProCost"
																		style={{ paddingRight: '0', textAlign: "left", paddingLeft: "47px" }}
																	>
																		QTY{' '}
																	</div>
																	<div
																		class="dentalProDis"
																		style={{ paddingLeft: '0px', marginLeft: "37px" }}
																	>
																		x&nbsp;&nbsp;&nbsp;&nbsp;COST
																	</div>
																</th>
																<th class="col-md-1" />

																<th class="col-md-2">
																	<div class="dentalProTotal">TAX (INR)</div>
																</th>
																<th class="col-md-3  ">
																	<div
																		class="dentalProNote"
																		style={{ textAlign: 'right' }}
																	>
																		TOTAL(INR)
																	</div>
																</th>
															</tr>
														</thead>
														{treatmentPlans.length > 0 ? (
															treatmentPlans.map((treatmentObj, i) => (
																<tbody
																	style={{ width: '100%', display: 'block', borderBottom: '1px solid #cecece' }}
																	className={
																		treatmentObj.isPaymentCompleted === '1' ? (
																			'treatmentSectionDisable'
																		) : (
																			''
																		)
																	}
																>
																	<span
																		className="close_row"
																		title="Remove"
																		onClick={(e) =>
																			removeTreatmentPlan(
																				i,
																				treatmentObj,
																				'procedureId' +
																				treatmentObj.pmHospitalTreatmentDetailsId
																			)}
																	>
																		x
																	</span>
																	<tr class="groupBrdr">
																		<td class="col-md-3">
																			<div
																				class="dentalProName treatmntName"
																				style={{
																					padding: '0px 7px',
																					color: '#000',
																				}}
																			>
																				<span
																					title={treatmentObj.treatmentName}
																				>
																					<b
																						style={{
																							color: '#333',
																							fontSize: '14px',
																							fontFamily:
																								'SourceSansPro-Regular',
																						}}
																					>
																						{treatmentObj.treatmentName}
																					</b>
																				</span>
																				<br />
																				<span
																					class="elipsis"
																					title={treatmentObj.treatmentCode}
																					style={{
																						fontSize: '14px',
																						color: '#333',
																						fontFamily:
																							'SourceSansPro-Regular',
																					}}
																				>
																					{treatmentObj.treatmentCode}
																				</span>
																			</div>
																		</td>
																		<td class="col-md-2">
																			<div class="treatmentPrice">
																				<div class="treatmentPriceTxt">
																					<span class="ui-spinner ui-corner-all ui-widget ui-widget-content">
																						<input
																							//onKeyPress={return false}
																							type="number"
																							min="1"
																							name="memberProcedureDentalDetailsQuantity"
																							class="quantity"
																							id={'txtQuantity' + i}
																							//onKeyPress={(e)=>disableQuantity(e,i, "txtQuantity")}
																							onChange={(e) => onChangeQuantity(e, i, "txtQuantity")}
																							//onKeyUp={() =>costAndQuantityCalculation()}
																							//onKeyUp={(e)=>disableQuantity(e)}
																							onKeyDown={(event) => {
																								event.preventDefault();
																							}}
																							value={
																								treatmentObj.memberProcedureDentalDetailsQuantity
																							}
																						/>
																					</span>{' '}
																				</div>
																				<div class="treatmentPriceCross">x</div>
																				<div class="treatmentCostTxt">
																					<input
																						type="text"
																						autocomplete="off"
																						name="memberProcedureDentalDetailsTreatmentCost"
																						maxlength="10"
																						id={'treatmentCost' + i}
																						onChange={(e) => onChangeQuantity(e, i, "treatmentCost")}
																						//onKeyUp={() =>costAndQuantityCalculation()}
																						value={
																							treatmentObj.memberProcedureDentalDetailsTreatmentCost
																						}
																					/>
																				</div>
																			</div>
																			<div
																				className="treatmentTeethShow"
																				style={{
																					display:
																						treatmentObj.showTeeth === 0
																							? 'none'
																							: 'block', paddingLeft: "8px"
																				}}
																			//onClick={() => showTeethListing(i)}
																			>
																				<span
																					id={'showTeeth' + i}
																					onClick={() => showTeethListing(i)}
																					title='Show Teeth'
																				>
																					Show Teeth
																				</span>
																				<span className='click_scroll'
																					onClick={() => showTeethListing(i)}
																					id={'txtTeeth' + i}
																					style={{ display: 'none' }}
																				>
																					Teeth :{' '}
																					{treatmentObj.memberProcedureDentalDetailsTeethNumber}
																				</span>
																			</div>
																		</td>

																		<td class="col-md-2 pull-right">
																			{' '}
																			{/* <Link target={"new"}
																			style={{
																				textAlign: 'right',
																				width: '100%',
																				float: 'left',
																				color: '#00abff',
																				cursor: 'pointer',
																				display:
																				treatmentObj.isConsentForm === 0
																						? 'none'
																						: 'block',
																			}}
																				to={{
																					pathname:
																						'/patients/consentFormInvoice',
																					state: {
																						hospitalId: localStorage.getItem('hospitalId'),
																						memberId: window.sessionStorage.getItem('memberId'),
																						doctorId: window.sessionStorage.getItem('patientDoctorId')
																					},
																				}}
																			> */}
																			{/* Consent Form */}

																			<a
																				target="new"
																				href={
																					'/patients/consentFormInvoice/' +
																					localStorage.getItem(
																						'hospitalId'
																					) +
																					'/' +
																					window.sessionStorage.getItem(
																						'memberId'
																					) +
																					'/' +
																					window.sessionStorage.getItem(
																						'patientDoctorId'
																					)
																				}
																				style={{
																					textAlign: 'center',
																					width: '100%',
																					float: 'left',
																					color: '#00abff',
																					cursor: 'pointer',
																					display:
																						treatmentObj.isConsentForm === 0
																							? 'none'
																							: 'block',
																				}}
																				title='Consent Form'
																			>
																				Consent Form
																			</a>
																			{/* </Link> */}
																		</td>
																		<td class="col-md-1">
																			<div
																				class="dentalProDis taxAddTreat"
																				style={{
																					textAlign: 'left',
																					paddingLeft: '0px',
																					color: '#008000',
																				}}
																			>
																				{parseFloat(
																					treatmentObj.memberProcedureDentalDetailsTaxIndividual
																				).toFixed(2)}
																			</div>
																			{/*  */}
																			{treatmentObj.taxDetails.length >
																				0 && (
																					<div className='inventryOuter addTreatTaxDetails' style={{ textAlign: "right" }}>
																						<span
																							onClick={() => showTaxDetails(i, "taxListDiv")}
																						>
																							<a style={{ color: "#00abff", cursor: "pointer" }}>Tax Details</a>
																						</span>
																						<ul
																							class="treatmentDrNameList pl_drList inventortDetailsCompltdplan"
																							id={"taxListDiv" + i}
																							style={{ display: 'none' }}
																						>
																							{treatmentObj.taxDetails.map(
																								(taxObj, inv) => (
																									<li>
																										<span className="inventoryInfoWrap">
																											<span>{taxObj.taxName}{' '}</span>
																											<span>{parseFloat(taxObj.taxAmountData).toFixed(2)}</span>
																										</span>
																									</li>
																								)
																							)}
																						</ul>
																					</div>
																				)}
																			{/*  */}


																		</td>
																		<td class="col-md-2">
																			<span
																				className="totalinr taxAddTreat"
																				style={{ color: '#008000' }}
																				id={'procedureFinalAmount' + i}
																				name="memberProcedureDentalDetailsGrandIndividual"
																			>
																				{parseFloat(
																					treatmentObj.memberProcedureDentalDetailsGrandIndividual
																				).toFixed(2)}
																			</span>
																		</td>
																	</tr>
																	<tr
																		class="dentalProPlaned"
																		style={{ paddingTop: '0px' }}
																	>
																		<input
																			type="hidden"
																			name="hidTreatmentIds00"
																			id="hidTreatmentIds00"
																			value=""
																		/>
																		<td colspan="3">
																			<div
																				class="plannedBy "
																				style={{ padding: '0px 7px 8px' }}
																			>
																				<span
																					id={'addNoteLabel' + i}
																					maxlength="250"
																					onClick={(e) => addNotes(i)}
																					style={{
																						fontWeight: '400',
																						fontSize: '14px',
																					}}
																					title='Add Notes'
																				>
																					+ add notes
																				</span>
																				<input
																					style={{ display: 'none' }}
																					id={'addNote' + i}
																					name="memberProcedureDentalDetailsNotes"
																					type="text"
																					placeholder="Add your notes"
																					value={
																						treatmentObj.memberProcedureDentalDetailsNotes
																					}
																					onChange={(e) =>
																						onChangeAddNotes(e, i)}
																				/>
																			</div>
																		</td>
																	</tr>

																	<TeethSection
																		setTeethInfo={setTeethInfo}
																		index={i}
																		teethObject={treatmentObj}
																	/>
																</tbody>
															))
														) : (
															<span>
																<span class="treatmentCommonTxt1">
																	<span>
																		{' '}
																		Add Procedure from right, Multiple Procedure(s)
																		can be added
																	</span>
																</span>
															</span>
														)}
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								{treatmentPlans.length > 0 ? (
									<div className="row total_info">
										<div className="col-md-5" style={{ paddingLeft: '0px' }}>
											{/* ========== Dropdown begins here  =============== */}
											<span
												class="treatmentGrandBy"
												id="doctorListSection"
												style={{ position: 'absolute' }}
											>
												<span>By</span>
												<span class="treatmentByName elipsis" onClick={doctorDropdown}>
													{doctorName}
												</span>
												<div
													class="treatmentDrNameList pl_drList"
													id="doctorListDiv"
													style={{ display: 'none' }}
												>
													{doctorList.map((doctorObj, i) => (
														<ul>
															<li onClick={(e) => setDoctorName(doctorObj, i)}>
																<span id={'doctorNameLabel' + i}>
																	{doctorObj.doctorName}
																</span>
															</li>
														</ul>
													))}
												</div>
											</span>
											{/* =========== Dropdown ends here ============== */}
											<br />

											{/* ========= Updated Datepicker begins here ================ */}
											<span className='taskDate' style={{ float: 'left' }}>
												On&nbsp;<span style={{ color: '#1b99d5' }} onClick={selectDatePicker} />
												<Datepicker
													required
													id="treatmentDateTime"
													className="Datepicker pa2  "
													minDate={new Date()}
													dateFormat="dd/MM/yyyy h:mm aa"
													placeholderText=""
													calendarClassName="rasta-stripes"
													selected={newDate}
													showTimeSelect
													onChange={(date) => setDate(date)}
													style={{ background: 'transparent!important' }}
												/>
											</span>

											{/* ========= Updated Datepicker begins here ================ */}
										</div>
										<div className="col-md-7">
											<div className="row AmountInfo">
												<div className="col-md-3" style={{ padding: '0px' }}>
													<p>Total Cost INR</p>
													<p>{parseFloat(totalCost).toFixed(2)}</p>
												</div>
												<div
													className="col-md-1"
													style={{ display: 'flex', alignItems: 'end' }}
												>
													<p> &nbsp; +</p>
												</div>
												<div className="col-md-3" style={{ padding: '0px' }}>
													<p>Total Tax INR</p>
													<p>{parseFloat(totalTax).toFixed(2)} </p>
												</div>
												<div
													className="col-md-1"
													style={{ display: 'flex', alignItems: 'end' }}
												>
													<p> &nbsp; =</p>
												</div>
												<div className="col-md-4" style={{ padding: '0px' }}>
													<p> Grand Total INR</p>
													<p>{parseFloat(grandTotal).toFixed(2)}</p>
												</div>
											</div>
										</div>
									</div>
								) : (
									''
								)}
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<div className="patientright pl_pro">
									<div className="rightTagSection">
										<div className="rightTagList">
											<div className="rightTagTitle">
												Procedures ( in INR )
												<div class="treatmentSideBtn">
													<span
														class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour"
														id="addPro"
														onClick={addProcedure}
													>
														+ Add
													</span>
													<span
														style={{ display: 'none' }}
														class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour"
														id="closePro"
														title="Close"
														onClick={closeProcedure}
													>
														x
													</span>
													{/* <a href=""> */}
													<img
														onClick={uploadProcedureCSV}
														src={TopArrow}
														title="Browse CSV file ( Columns must be in the format :- ProcedureName | ProcedureCode | BranchName | Amount )"
														class="treatmntPlanCSV_PTour completedPlanCSV_PTour"
													/>
													<input
														style={{ display: 'none' }}
														onChange={(e) => uploadProcedureCSVFile(e)}
														type="file"
														id="uploadProcedureCSVFile"
														name="file1"
														accept=".csv,.xlsx,xls"
														class="upload_upload"
													/>
													{/* </a> */}
													<a
														href="https://purplehealthfiles.s3.amazonaws.com/production/pulse/csv/procedure_template.csv"
														download
													>
														<img
															src={DownArrow}
															title="Download Template"
															class="treatmntPlanCSV_PTour completedPlanCSV_PTour"
														/>
													</a>
													<span
														class="trtmntPlanBtn"
														style={{ display: 'none' }}
														id="removePro"
														title="Close"
													>
														x
													</span>
												</div>
												{/* -===============  (PROCEDURE ADD DESIGN BEGINS HERE )  =============== */}
												<div
													class="treatmentListNew bg-whitesec mt-3"
													id="addProNew"
													style={{ display: 'none' }}
												>
													<div class="treatmentNewAdd completedPlanProFillForm_PTour treatmntPlanProFillForm_PTour">
														<input
															type="text"
															placeholder="Name*"
															id="procedureName"
															name="procedureName"
															class="completedPlanProName_PTour treatmntPlanProName_PTour"
															value={procedureData.procedureName}
															onChange={onChange}
														/>
														<input
															type="text"
															placeholder="Code*"
															id="procedureCode"
															name="procedureCode"
															value={procedureData.procedureCode}
															class="completedPlanProCode_PTour treatmntPlanProCode_PTour"
															onChange={onChange}
														/>
														<input
															type="text"
															placeholder="Amount*"
															id="procedureAmount"
															name="procedureAmount"
															maxlength="10"
															value={procedureData.procedureAmount}
															class="completedPlanProAmount_PTour treatmntPlanProAmount_PTour"
															onChange={(e) => numberValidation(e)}
														/>
														<span>INR</span>
														{/* <div
															class="saVeBtn completedPlanProSave_PTour treatmntPlanProSave_PTour"
															id="savePro"
															onClick={(e) => procedureSave()}
															style={{ fontWeight: 'normal' }}
														>
															Save
														</div> */}

														{medicineBranchId === '4' && (
															<div class="treatmentNewAddchck">
																<input
																	type="checkbox"
																	id="chkShowTeeth"
																	name="chkShowTeeth"
																	checked={procedureData.chkShowTeeth}
																	onChange={onChangeCheckBox}
																/>
																<label>Show Teeth</label>
																<input
																	type="checkbox"
																	id="chkMultipleCost"
																	name="chkMultipleCost"
																	checked={procedureData.chkMultipleCost}
																	onChange={onChangeCheckBox}
																/>
																<label>Multiply Cost with Teeth</label>
																<input
																	type="checkbox"
																	id="chkConcentForm"
																	name="chkConcentForm"
																	checked={procedureData.chkConcentForm}
																	onChange={onChangeCheckBox}
																/>
																<label>Consent Form</label>
															</div>
														)}
														{window.sessionStorage.getItem("siteBranchId") === "4" &&
															<div class="taxDetailWrapper mt-1">
																<div className="row">
																	<label class="col-md-4 smalLbl" style={{ paddingLeft: "12px", paddingTop: "4px" }}>Treatment Branch</label>
																	<div class="col-md-7 taxMainContentWrap mb-0 pb-0" style={{ paddingLeft: "2px" }}>
																		<div class="taxMainWrap">
																			<select name="procedureCategoryId"
																				id="accountHead"
																				className='inputField'
																				value={procedureCategoryId}
																				onChange={procedureCategoryChange}
																			>
																				<option value="">Select</option>
																				{procedureCategoryValues.map((item, idix) => (
																					<option value={item.id}>
																						{item.branchName}
																					</option>
																				))}
																			</select>

																		</div>
																	</div>
																</div>
															</div>
														}

														<div class="taxDetailWrapper mt-1">
															<label class="smalLbl">Material / Items used</label>
															<div class="taxMainContentWrap mb-0 pb-0">
																<div class="taxMainWrap">
																	<input
																		type="hidden"
																		name="hndItemCount"
																		id="hndItemCount"
																		value="0"
																	/>
																	<select
																		class="completedPlanProCatgry_PTour treatmntPlanProCatgry_PTour"
																		id="categoryId1"
																		name="categoryId1"
																		value={categoryId1}
																		onChange={(e) => setCategoryId1(e.target.value)}
																	>
																		<option value="0">Select Category</option>
																		<option value="1">Medicine</option>
																		<option value="2">disposable</option>
																		<option value="3">Others</option>
																		<option value="4">Instruments</option>
																		<option value="5">Materials</option>
																	</select>
																	<input
																		type="text"
																		placeholder="Material / Item Name"
																		id="itemName"
																		name="itemName"
																		value={itemName}
																		class="completedPlanProItemName_PTour treatmntPlanProItemName_PTour"
																		onChange={(e) => setItemName(e.target.value)}
																	/>
																	<input
																		type="text"
																		placeholder="Quantity"
																		name="itemQty"
																		id="itemQty"
																		value={itemQty}
																		style={{ width: '60px' }}
																		class="completedPlanProQty_PTour treatmntPlanProQty_PTour"
																		onChange={(e) => itemQtyValidation(e)}
																	/>
																	<span
																		class="trtmntPlanBtn completedPlanProAddCatgry_PTour treatmntPlanProAddCatgry_PTour"
																		onClick={(e) => addRow(e)}
																	>
																		+
																	</span>
																	<input type="hidden" id="txtCategoryId0" />
																</div>
																{procedureArray.map((obj, i) => (
																	<div class="taxMainWrap">
																		<input
																			type="hidden"
																			name="hndItemCount"
																			id="hndItemCount"
																			value="0"
																		/>
																		<select
																			class="completedPlanProCatgry_PTour treatmntPlanProCatgry_PTour"
																			id="categoryId"
																			name="categoryId"
																			value={obj.categoryId}
																			onChange={(e) => onChangeCategory(e, i)}
																		>
																			<option value="0">Select Category</option>
																			<option value="1">Medicine</option>
																			<option value="2">disposable</option>
																			<option value="3">Others</option>
																			<option value="4">Instruments</option>
																			<option value="5">Materials</option>
																		</select>
																		<input
																			type="text"
																			placeholder="Material / Item Name"
																			value={obj.itemName}
																			id="itemName"
																			name="itemName"
																			class="completedPlanProItemName_PTour treatmntPlanProItemName_PTour"
																			onChange={(e) => onChangeCategory(e, i)}
																		/>
																		<input
																			type="text"
																			placeholder="Quantity"
																			value={obj.itemQty}
																			name="itemQty"
																			id="itemQty"
																			style={{ width: '60px' }}
																			class="completedPlanProQty_PTour treatmntPlanProQty_PTour"
																			onChange={(e) => onChangeCategory(e, i)}
																		/>
																		<span
																			class="trtmntPlanBtn"
																			onClick={(e) => removeRow(e, i)}
																		>
																			x
																		</span>
																		<input type="hidden" id="txtCategoryId0" />
																	</div>
																))}
																<div id="divItem" />
															</div>
														</div>

														<div className="text-right">
															<div
																class="saVeBtn completedPlanProSave_PTour treatmntPlanProSave_PTour"
																id="savePro"
																onClick={(e) => procedureSave()}
																style={{ fontWeight: 'normal' }}
															>
																Save
															</div>
															<div
																class="saVeBtn completedPlanProSave_PTour treatmntPlanProSave_PTour bg-bluebtn"
																id="closePro"
																title="Close"
																onClick={closeProcedure}
															>
																Cancel
															</div>
														</div>
													</div>
												</div>
												{/* ===============  (PROCEDURE ADD DESIGN ENDS HERE) =============== */}
											</div>
											<div className="row mt-2">
												{window.sessionStorage.getItem("siteBranchId") === "4" &&
													<div class="taxMainWrap col-md-6 pb-0 mb-0 mt-1">
														<select name="procedureCategoryIdSearch"
															id="accountHead"
															className='inputField'
															value={procedureCategoryIdSearch}
															onChange={procedureCategoryIdSearchChange}
														>
															<option value="">Select Treatment Branch</option>
															{procedureCategoryValues.map((item, idix) => (
																<option value={item.id}>
																	{item.branchName}
																</option>
															))}
														</select>

													</div>
												}
												<div
													className={`tagListingDiv ${window.sessionStorage.getItem("siteBranchId") === "4" ? "col-md-6" : "col-md-12"} pb-0 mb-0 mt-1`}
												>
													<input
														type="text"
														className="inputField w-100"
														placeholder="Search"
														autoComplete='off'
														name="procedureSearch"
														value={procedureSearch}
														onChange={(e) => searchProcedure(e)}
													/>
												</div>
											</div>


										</div>
									</div>
									<div className="treatmentNameList treatmentPlanList ">
										{/* Teeth Showing list begins */}
										{/* <ul>
											<li>
												<span class="elipsis">
													<b>Teeth Showing</b>
												</span>
												<span class="elipsis" style={{ fontSize: '10px' }}>
													test
												</span>
												<span class="treatmentNameListRight">
													<span class="treatmentNameListAmt">₹ 200</span>
												</span>
											</li>
										</ul> */}
										{/* Teeth Showing list ends */}

										<ul id="tabProceduresUL">

											{procedureList.length > 0 ?

												procedureList.map((procedureObj, p) => (
													<li
														id={'procedureId' + procedureObj.procedureId}
														onClick={() =>
															addNewProcedure(
																procedureObj,
																p,
																'procedureId' + procedureObj.procedureId
															)}
													>
														<span class="elipsis" title={procedureObj.procedureName}>
															<b>{procedureObj.procedureName}</b>
														</span>

														<span
															class="elipsis"
															title={procedureObj.procedureCode}
															style={{ fontSize: '12px' }}
														>
															{procedureObj.procedureCode == "" ? <span>&nbsp;</span> : procedureObj.procedureCode}
														</span>
														{window.sessionStorage.getItem("siteBranchId") === "4" &&
															<span
																class="elipsis"
																title={procedureObj.pmTreatmentsBranchName}
																style={{ fontSize: '10px' }}
															>
																{procedureObj.pmTreatmentsBranchName == "" ? <span>&nbsp;</span> : procedureObj.pmTreatmentsBranchName}
															</span>
														}
														<span class="treatmentNameListRight treatmentAmount-right">
															<span class="treatmentNameListAmt">
																₹ {parseFloat(procedureObj.price).toFixed(2)}
															</span>
														</span>
													</li>
												))
												: "No Procedure Found"}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AddTreatmentPlans;
