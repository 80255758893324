import React from 'react';
import Logo from '../images/avatarHospitalDefault.png';
import { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import AWS from 'aws-sdk';
import Loader from '../Common/loader';
import girl from '../images/media/blankImg.jpg'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { uploadToS3 } from '../uploadToS3';

var s3;

var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
function DoctorPrescription() {
	const [dataFlag, setDataFlag] = useState(false);
	const [doctorPrescriptionData, setDoctorPrescriptionData] = useState({});
	const [file, setFile] = useState('');
	const [flag, setFlag] = useState(false);
	const [showHeader, setShowHeader] = useState(true);
	const [showFooter, setShowFooter] = useState(true);
	const [showRxLabel, setShowRxLabel] = useState(true);
	const [hospName, setHospName] = useState('');
	const [hospAddress, setHospAddress] = useState('');
	const [showCustomData, setShowCustomData] = useState('');
	const [titleAlignment, setTitleAlignment] = useState('');
	const [footerAlignment, setFooterAlignment] = useState('');

	const [selctedPrintoutPageSizeID, setSelctedPrintoutPageSizeID] = useState(0);
	const [printOutPageSizeDropDown, setPrintOutPageSizeDropDown] = useState([]);
	const [printOutDoctorHeaderLeftText, setprintOutDoctorHeaderLeftText] = useState([]);
	const [printOutDoctorHeaderRightText, setprintOutDoctorHeaderRightText] = useState([]);

	const [printOutDoctorFooterFullWidthContent, setprintOutDoctorFooterFullWidthContent] = useState([]);

	const [printOutDoctorId, setPrintOutDoctorId] = useState('');
	const [showEditDiv, setShowEditDiv] = useState(false);

	const [DisplayLogoFlag, setDisplayLogoFlag] = useState(false);
	const [DisplayLogoFormatFlag, setDisplayLogoFormatFlag] = useState(false);
	const [imageName, setImageName] = useState();
	const [PictureName, setPictureName] = useState('');
	const [pictureForUpload, setPictureForUpload] = useState('');
	const [includeLogo, setIncludeLogo] = useState(true);
	const [imageflag, setimageflag] = useState(false);
	const [logoType, setIncludeLogoType] = useState(0);
	const [printOutLogoTypes, setPrintOutLogoTypes] = useState([]);
	const [flagsave, setflagsave] = useState(false);
	const [flagsave1, setflagsave1] = useState(false);
	const [getPicturename, setgetPicturename] = useState('');
	const navigate = useNavigate();


	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	const textchangeleft = (e) => {
		setprintOutDoctorHeaderLeftText(e.currentTarget.value);
	};
	const textchangeright = (e) => {
		setprintOutDoctorHeaderRightText(e.currentTarget.value);
	};
	const textchangefooterfullwidth = (e) => {
		setprintOutDoctorFooterFullWidthContent(e.currentTarget.value);
	};

	const cancelUpdate = () => {
		setShowEditDiv(false);
	};




	function IncludeLogo() {
		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'block';
		document.getElementById('wide').style.display = 'block';
		document.getElementById('square').style.display = 'block';
		x.style.display = 'none';
		document.getElementById('myDIVUpload').style.display = 'none';
		setFlag(false);
	}

	function NotIncludeLogo(e) {
		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'block';
		document.getElementById('wide').style.display = 'block';
		document.getElementById('square').style.display = 'block';
		x.style.display = 'none';
		document.getElementById('myDIVUpload').style.display = 'none';
		setFlag(true);
	}
	const onChangePictureUpload = (e) => {

		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'block';
		document.getElementById('wide').style.display = 'block';
		document.getElementById('square').style.display = 'block';
		x.style.display = 'none';
		setFile(e.target.files[0]);
		if (e.target.files.length !== 0) {
			setimageflag(false)
			setPictureForUpload(URL.createObjectURL(e.target.files[0]));
			setImageName(e.target.files[0].name.replace(/ /g, '-'));

			setPictureName('')
		}


	};

	useEffect(() => {
		const postData = {
			hospitalId: localStorage.getItem("hospitalId"),
		}
		callingAPI('doctorPrescription', postData).then((res) => {
			if (res.data.success === '1') {
				setDataFlag(true);
				setprintOutDoctorFooterFullWidthContent(
					res.data.result.hospitalPrintOutList[0].printOutDoctorFooterFullWidthContent
				);
				setHospName(res.data.result.hospitalPrintOutList[0].hospitalName)
				setHospAddress(res.data.result.hospitalPrintOutList[0].hospitalAddress)
				setPrintOutDoctorId(res.data.result.hospitalPrintOutList[0].printOutDoctorId)
				setprintOutDoctorHeaderLeftText(res.data.result.hospitalPrintOutList[0].printOutDoctorHeaderLeftText);
				setprintOutDoctorHeaderRightText(res.data.result.hospitalPrintOutList[0].printOutDoctorHeaderRightText);
				setDoctorPrescriptionData(res.data.result.hospitalPrintOutList[0]);
				setIncludeLogo(res.data.result.hospitalPrintOutList[0].printOutDoctorHeaderIncludeLogo);
				setIncludeLogoType(res.data.result.hospitalPrintOutList[0].printOutLogoTypeId);
				setSelctedPrintoutPageSizeID(res.data.result.hospitalPrintOutList[0].printOutPageSizeId);
				setShowHeader(res.data.result.hospitalPrintOutList[0].printOutDoctorShowHeader);
				setShowFooter(res.data.result.hospitalPrintOutList[0].printOutDoctorShowFooter);
				setPictureName(res.data.result.hospitalPrintOutList[0].hospitalImagesLogoImage);
				setImageName(res.data.result.hospitalPrintOutList[0].hospitalImagesLogoImage)
				setShowRxLabel(res.data.result.hospitalPrintOutList[0].printOutDoctorShowRx);
				setPrintOutPageSizeDropDown(res.data.result.printOutPageSize);
				setPrintOutLogoTypes(res.data.result.printOutLogoType);
				setShowCustomData(res.data.result.hospitalPrintOutList[0].printOutPageSizePageSizeName);
				setTitleAlignment(res.data.result.hospitalPrintOutList[0].printOutDoctorHeaderAllign);
				setFooterAlignment(res.data.result.hospitalPrintOutList[0].printOutDoctorFooterAllign);
				setPictureName(res.data.result.hospitalPrintOutList[0].hospitalImagesLogoImage);
				setgetPicturename(res.data.result.hospitalPrintOutList[0].hospitalImagesLogoImage);

			} else {
				setDataFlag(true);
			}

		});
	}, []);
	const selectedPgeSizeOnChange = (e) => {
		setSelctedPrintoutPageSizeID(e.target.value);
		var t = document.getElementById('pagesizeName');
		var selectedTextForPageSize = t.options[t.selectedIndex].text;
		setShowCustomData(selectedTextForPageSize);
	};
	const saveDoctorPrescriptionData = () => {

		if (showCustomData === 'Customized' && document.getElementById('custwidth').value == '') {
			toast.error('Custome width required');
			return;
		}
		if (showCustomData === 'Customized' && document.getElementById('custheight').value == '') {
			toast.error('Custome height required');
			return;
		}
		setDataFlag(false);
		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			printOutDoctorCustomWidth:
				showCustomData === 'Customized'
					? document.getElementById('custwidth').value
					: doctorPrescriptionData.printOutDoctorCustomWidth,
			printOutDoctorCustomHeight:
				showCustomData === 'Customized'
					? document.getElementById('custheight').value
					: doctorPrescriptionData.printOutDoctorCustomHeight,
			printOutDoctorFooterFullWidthContent: document.getElementById('footerFullWidthContent').value,
			printOutDoctorShowFooter: showFooter,
			printOutDoctorShowHeader: showHeader,
			hospitalImagesLogoImage: (imageName == null || includeLogo == false) ? "null" : imageName,
			printOutDoctorShowRx: showRxLabel,
			printOutDoctorFooterAllign: footerAlignment,
			printOutDoctorHeaderAllign: titleAlignment,
			printOutDoctorHeaderIncludeLogo: includeLogo,
			printOutDoctorHeaderLeftText: document.getElementById('leftText').value,
			printOutLogoTypeId: logoType,
			printOutDoctorHeaderRightText: document.getElementById('rightText').value,
			printOutDoctorHeaderTitle: doctorPrescriptionData.printOutDoctorHeaderTitle,
			hospitalId: localStorage.getItem("hospitalId"),
			printOutDoctorPageMarginBottom: '',
			printOutDoctorPageMarginLeft: '',
			printOutDoctorPageMarginRight: '',
			printOutDoctorPageMarginTop: '',
			printOutOrientationTypeId: 1,
			printOutPrinterTypeId: 1,
			printOutPageSizeId: document.getElementById('pagesizeName').value,
			printOutDoctorIsDefault: 1,
			isActive: 1,
			printOutDoctorId: printOutDoctorId,

		};

		callingAPI('doctorPrescription/doctorPrescriptionAddUpdate', postData).then(async(res) => {
			if (res.data.success == "1") {
				setDataFlag(true);
				if (file != '') {
				imageS3Save();

				}
				//window.location.reload()
				toast.success('Doctor Prescription Saved successfully');


				setTimeout(function () {
					// window.location.href = '/settings/doctorPrescription';
					navigate('/settings/doctorPrescription');
				}, 1400);


			} else {
				setDataFlag(true);
				toast.error('failed');
			}
		});

	};

	const getIncludeLogo = (e) => {
		if (e.currentTarget.value === 'Yes') {
			setIncludeLogo(true);
		} else {
			setIncludeLogo(false);
		}
	};


	const getLogoType = (e) => {
		setIncludeLogoType(e.currentTarget.value);
		if (e.currentTarget.value === '1') {
			setShowEditDiv(false);
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('wide').style.display = 'none';
			document.getElementById('square').style.display = 'block';
			document.getElementById('myDIV').style.display = 'none';
		} else if (e.currentTarget.value === '2') {
			setShowEditDiv(false);
			document.getElementById('wide').style.display = 'none';
			document.getElementById('narrow').style.display = 'block';
			document.getElementById('square').style.display = 'none';
			document.getElementById('myDIV').style.display = 'none';
		} else {
			setShowEditDiv(false);
			document.getElementById('wide').style.display = 'block';
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('square').style.display = 'none';
			document.getElementById('myDIV').style.display = 'none';
		}
	};
	function UploadPictureValidation() {
		const DisplayLogo = () => {
			var x = document.getElementById('myDIV');
			x.style.display = 'none';
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('wide').style.display = 'none';
			document.getElementById('square').style.display = 'none';
		};
		//Get reference of FileUpload.
		var fileUpload = document.getElementById('fileUpload');

		//Check whether the file is valid Image.
		var regex = new RegExp('([a-zA-Z0-9s_\\.-:])+(|.jpg|.png|.gif)$');
		if (regex.test(fileUpload.value.toLowerCase())) {
			//Check whether HTML5 is supported.
			if (typeof fileUpload.files != 'undefined') {
				//Initiate the FileReader object.
				var reader = new FileReader();
				//Read the contents of Image File.
				reader.readAsDataURL(fileUpload.files[0]);
				reader.onload = function (e) {
					//Initiate the JavaScript Image object.
					var image = new Image();

					//Set the Base64 string return from FileReader as source.
					image.src = e.target.result;

					//Validate the File Height and Width.
					image.onload = function () {
						var height = this.height;
						var width = this.width;

						const NotDisplayLogo = () => {
							var x = document.getElementById('myDIV');
							x.style.display = 'block';
							document.getElementById('narrow').style.display = 'none';
							document.getElementById('wide').style.display = 'none';
							document.getElementById('square').style.display = 'none';
						};
						const DisplayLogo = () => {
							var x = document.getElementById('myDIV');
							x.style.display = 'none';
							document.getElementById('narrow').style.display = 'none';
							document.getElementById('wide').style.display = 'none';
							document.getElementById('square').style.display = 'none';
						};
						if (height < 50 || width < 50) {
							setflagsave(true)

							setDisplayLogoFlag(height < 50 || width < 50)

							setTimeout(function () {
								toast.error('Width should be:50px and Height should be:50px');
							}, 80);
							return DisplayLogo();
						}
						setflagsave(false)
						setDisplayLogoFlag()

						return NotDisplayLogo;
					};
				};
			} else {

				toast.error('This browser does not support HTML5.');
				return DisplayLogo();
			}

		} else {
			setflagsave1(true)
			setTimeout(function () {
				toast.error('Please attach jpeg or png files');
			}, 80);

			setDisplayLogoFormatFlag(true)
			return DisplayLogo();

		}
		setflagsave1(false)
		setDisplayLogoFormatFlag()
	}

	const imageS3Save = async(e) => {
		var S3BucketMainFolder =
			process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH + '/';
		var fullFilePath = S3BucketMainFolder + + localStorage.getItem("hospitalId") + ".png";
		const s3Url = await uploadToS3(file, process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH)
				  console.log("🚀 ~ imageS3Save ~ s3Url 1:", s3Url)

		// s3.upload(
		// 	{
		// 		Key: fullFilePath,
		// 		Body: file,
		// 		ACL: 'public-read',
		// 	},
		// 	(err, data) => {
		// 		if (err) {
		// 			toast.error('There was an error uploading your photo: ' + err.message);
		// 			return null;
		// 		} else {


		// 		}
		// 	}
		// );

	};
	const getLogoNochange = (e) => {
		if (flag == false) {
			getLogoType(e);
		} else {
			return false;
		}
	};
	const showHeaderText = () => {
		if (!showHeader == true) {
			setShowEditDiv(false);
		}
	};
	const showFooterText = () => {
		if (!showFooter == true) {
			setShowEditDiv(false);
		}
	};
	const showRxlabelText = () => {
		if (!showRxLabel == true) {
			setShowEditDiv(false);
		}
	};
	const FooterAlignment = (e) => {
		if (e.currentTarget.value == 'Right') {
			document.getElementById('footright').style.display = 'block';
			document.getElementById('footleft').style.display = 'none';
			document.getElementById('footcenter').style.display = 'none';
		} else if (e.currentTarget.value == 'Left') {
			document.getElementById('footright').style.display = 'none';
			document.getElementById('footleft').style.display = 'block';
			document.getElementById('footcenter').style.display = 'none';
		} else {
			document.getElementById('footright').style.display = 'none';
			document.getElementById('footleft').style.display = 'none';
			document.getElementById('footcenter').style.display = 'block';
		}
	};
	const TitleAlignment = (e) => {
		if (e.currentTarget.value == 'Right') {
			document.getElementById('titleright').style.display = 'block';
			document.getElementById('titleleft').style.display = 'none';
			document.getElementById('titlecenter').style.display = 'none';
		} else if (e.currentTarget.value == 'Left') {
			document.getElementById('titleright').style.display = 'none';
			document.getElementById('titleleft').style.display = 'block';
			document.getElementById('titlecenter').style.display = 'none';
		} else {
			document.getElementById('titleright').style.display = 'none';
			document.getElementById('footleft').style.display = 'none';
			document.getElementById('titlecenter').style.display = 'block';
		}
	};


	if (selctedPrintoutPageSizeID == '3') {

		var printsizevalueB4 = '96px'
	}
	else if (selctedPrintoutPageSizeID == '5') {
		var printsizevalueLetter = '100px'
	}
	return (
		<div className="practiceManagementForm">
			<ToastContainer position="bottom-right" />
			<div className="col-md-12 col-lg-12">
				<h3 className="practiceHead">Doctor Prescription </h3>
			</div>
			<div className="clearfix" />
			<div className="row">
				{dataFlag === false ? <Loader /> : ''}
				<div class="col-md-4 col-lg-4">
					<div class="practiceManagementFormBlcok">
						<form name="frm" enctype="multipart/form-data" id="frm">
							<input type="hidden" value="HOSPITAL" name="roleText" />
							<ul>
								<div class="LimitScrollDiv2" style={{ padding: '10px', paddingLeft: '0px' }}>
									<div class="clearfix">
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Header
										</h4>
										<li>
											<label style={{ fontWeight: 'normal' }}>
												Hospital Logo&nbsp;
												{/* <font color="red">Minimum Size(50x50px)</font> */}
											</label>

											{DisplayLogoFlag || DisplayLogoFormatFlag || PictureName == null ?

												<img src={Logo} /> :
												<img id="imageThumb2" src={

													imageflag == false ?
														pictureForUpload ||
														getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : Logo

												} />
											}
											<input
												type="file"
												onChange={(e) => {
													onChangePictureUpload(e);
													UploadPictureValidation();
												}}
												name="file"
												accept="image/x-png,image/gif,image/jpeg"
												id="fileUpload"
											/>
										</li>



										<li>
											<label style={{ fontWeight: 'normal' }}>Left Text</label>{' '}
											{doctorPrescriptionData.printOutDoctorHeaderLeftText == "" ?
												<input
													id="leftText"
													name="leftText"
													defaultValue={doctorPrescriptionData.hospitalName}
													placeholder="Left Text"
													onChange={textchangeleft}
													type="text"
													maxlength="250"
												/> : <input
													id="leftText"
													name="leftText"
													defaultValue={doctorPrescriptionData.printOutDoctorHeaderLeftText}
													placeholder="Left Text"
													onChange={textchangeleft}
													type="text"
													maxlength="250"
												/>}
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Right Text</label>{' '}
											{doctorPrescriptionData.printOutDoctorHeaderRightText == "" ?
												<input
													id="rightText"
													name="rightText"
													defaultValue={doctorPrescriptionData.hospitalAddress}
													placeholder="Right Text"
													type="text"
													onChange={textchangeright}
													maxlength="250"
												/> :
												<input
													id="rightText"
													name="rightText"
													defaultValue={doctorPrescriptionData.printOutDoctorHeaderRightText}
													placeholder="Right Text"
													type="text"
													onChange={textchangeright}
													maxlength="250"
												/>}
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Title Alignment</label>
											<select
												class=" fulWidthSelect"
												id="titleAlign"
												name="titleAlign"
												data-width="100%"
												value={titleAlignment}
												onChange={(e) => {
													setTitleAlignment(e.target.value);
													TitleAlignment(e);
												}}
											>
												<option value="Right">Right</option>
												<option value="Left">Left</option>
												<option value="Center">Center</option>
											</select>{' '}
										</li>
										<li class="no-gutter row">
											<div class="col-md-6 col-lg-6">
												<label style={{ fontWeight: 'normal' }}>Include Logo </label>
												<div class="practiceRadio practiceRadio-primary practiceRadioSet">
													<div class="radio">
														<input
															id="radio-1"
															value="Yes"
															onClick={getIncludeLogo}
															onChange={IncludeLogo}
															name="IncludeLogo"
															type="radio"
															checked={includeLogo}
														/>
														<label for="radio-1" class="radio-label">
															Yes
														</label>
													</div>

													<div class="radio">
														<input
															id="radio-2"
															value="No"
															onClick={getIncludeLogo}
															onChange={NotIncludeLogo}

															name="IncludeLogo"
															type="radio"
															checked={!includeLogo}
														/>
														<label for="radio-2" class="radio-label">
															No
														</label>
													</div>
												</div>
											</div>

											<div class="col-md-6 col-lg-6">
												<label style={{ fontWeight: 'normal' }}>Logo Type </label>
												<div class="practiceRadio practiceRadio-primary practiceRadioSet">
													{printOutLogoTypes.map((item) => (
														<div class="radio">
															<input
																id={'radio' + item.printOutLogoTypeId}
																value={item.printOutLogoTypeId}
																checked={logoType == item.printOutLogoTypeId}
																onChange={getLogoNochange}
																name={'logoType' + item.printOutLogoTypeId}
																type="radio"
															/>
															<label
																for={'radio' + item.printOutLogoTypeId}
																class="radio-label"
															>
																{item.printOutLogoTypeName}
															</label>
														</div>
													))}
												</div>
											</div>
										</li>
									</div>

									<div class="clearfix">
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Footer
										</h4>
										<li>
											<label style={{ fontWeight: 'normal' }}>Full Width Content</label>
											<input
												id="footerFullWidthContent"
												defaultValue={
													doctorPrescriptionData.printOutDoctorFooterFullWidthContent
												}
												placeholder="Full Width Content"
												onChange={textchangefooterfullwidth}
												type="text"
												name="footerfullWidth"
												maxlength="255"
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Footer Alignment</label>
											<select
												class=" fulWidthSelect"
												id="footerAligh"
												name="footerAligh"
												data-width="100%"
												value={footerAlignment}
												onChange={(e) => {
													setFooterAlignment(e.target.value);
													FooterAlignment(e);
												}}
											>
												<option value="Right">Right</option>
												<option value="Left">Left</option>
												<option value="Center">Center</option>
											</select>
										</li>
									</div>

									<div class="clearfix">
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Page Setup
										</h4>

										<li className="pl_chkbx">
											<div class="clearfix">
												<label style={{ fontWeight: 'normal' }}>
													{' '}
													Show Header
													<input
														type="checkbox"
														id="showHead"
														name="showHead"
														checked={showHeader}
														onChange={() => {
															setShowHeader(!showHeader);
															showHeaderText();
														}}
													/>
												</label>

												<div class="clearfix">
													<label style={{ fontWeight: 'normal' }}>
														{' '}
														Show Footer
														<input
															type="checkbox"
															id="showFoot"
															name="showFoot"
															checked={showFooter}
															onChange={() => {
																setShowFooter(!showFooter);
																showFooterText();
															}}
														/>
													</label>
													<div class="clearfix">
														<label style={{ fontWeight: 'normal' }}>
															{' '}
															Show Rx label
															<input
																type="checkbox"
																id="showRx"
																name="showRx"
																checked={showRxLabel}
																onChange={() => {
																	setShowRxLabel(!showRxLabel);
																	showRxlabelText();
																}}
															/>
														</label>
													</div>
												</div>
											</div>
										</li>
										<li>
											<div class="clearfix">
												<label style={{ fontWeight: 'normal' }}>Size</label>
												<div class="form-group">
													<select
														class=" fulWidthSelect"
														name="pagesize"
														id="pagesizeName"
														data-width="100%"
														value={selctedPrintoutPageSizeID}
														onChange={(e) => selectedPgeSizeOnChange(e)}
													>
														{printOutPageSizeDropDown.map((item) => (
															<option value={item.printOutPageSizeId}>
																{item.printOutPageSizeName}
															</option>
														))}
													</select>
												</div>
											</div>
										</li>
										{showCustomData == 'Customized' && (
											<li>
												<label style={{ fontWeight: 'normal' }}>Height </label>{' '}
												<input
													id="custheight"
													name="custheight"
													defaultValue={doctorPrescriptionData.printOutDoctorCustomHeight}
													placeholder="Height In Pixel(px)"
													type="text"
													maxlength="250"
												/>
											</li>
										)}
										{showCustomData == 'Customized' && (
											<li>
												<label style={{ fontWeight: 'normal' }}>Width </label>{' '}
												<input
													id="custwidth"
													name="custwidth"
													defaultValue={doctorPrescriptionData.printOutDoctorCustomWidth}
													placeholder="Width In Pixel(px)"
													type="text"
													maxlength="250"
												/>
											</li>
										)}
									</div>
								</div>

								<li>

									<button
										type="button"
										class="practiceGreenButton"
										onClick={() => {
											saveDoctorPrescriptionData();
											cancelUpdate();
										}}
										value="Save"
									>
										Save
									</button>

									{/* <!-- <button type="button" class="practiceGreyButton" value="Cancel">Cancel</button> --> */}
								</li>
							</ul>
						</form>
					</div>
				</div>
				<div class="col-md-8 col-lg-8 iframeBlockPractice" >

					<div id="iFrameReport">

						<div class="iframePrintOutDiv" align="center" style={{ width: '100%', margin: '0px' }}>
							<table style={{ width: '100%', maxWidth: '100%', marginLeft: printsizevalueB4 || printsizevalueLetter }} border="0">
								<tbody>
									<tr height="80px">
										<td colspan="3" valign="middle" align="center">
											<div class="practicePrintlogoSquare">
												<p
													style={{
														fontWeight: '400',
														fontSize: '14px',
														clear: 'both',
														width: '100%',
														margin: '0',
													}}
												>
													<span
														style={{
															marginTop: '-5px',
															display: 'inline-block',
															width: '100%',
														}}
													>
														<div id="myDIV" style={{ display: showEditDiv ? '' : 'none' }}>
															{includeLogo == true && (
																<img
																	src={
																		imageflag == false ?
																			pictureForUpload ||
																			getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																	}
																	style={{ display: !showEditDiv ? '' : 'none' }}
																	align="middle"
																/>
															)}
														</div>
														<div id="myDIVUpload">
															<img
																src={
																	imageflag == false ?
																		pictureForUpload ||
																		getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																}
																style={{ display: showEditDiv ? '' : 'none' }}
																align="middle"
															/>
														</div>

														<div
															id="narrow"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															{logoType == '2' &&
																includeLogo == true && (
																	<div>
																		{PictureName != null ?
																			<img
																				src={
																					imageflag == false ?
																						pictureForUpload ||
																						getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																				}
																				style={{
																					width: '140px',
																					height: 'auto',
																					float: 'left',
																				}}
																				align="left"
																			/> :
																			<img
																				src={
																					Logo
																				}
																				style={{
																					width: '140px',
																					height: 'auto',
																					float: 'left',
																				}}
																				align="left"
																			/>}

																	</div>
																)}
															{logoType == '2' &&
																includeLogo == false && (
																	<div>
																		{/* <img
																			src={
																				imageflag == false ?
																					pictureForUpload ||
																					getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																			}
																			style={{
																				width: '140px',
																				height: 'auto',
																				float: 'left',
																			}}
																			align="left"
																		/> */}

																	</div>
																)}
														</div>
														<div id="wide" style={{ display: !showEditDiv ? '' : 'none', width: "100%" }}>
															{logoType == '3' &&
																includeLogo == true && (
																	<div>
																		{PictureName != null ?
																			<img
																				src={
																					imageflag == false ?
																						pictureForUpload ||
																						getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																				}
																				style={{
																					width: '150px',
																					float: 'left',
																					height: 'auto',
																				}}
																				align="left"
																			/> :
																			<img
																				src={
																					Logo
																				}
																				style={{
																					width: '150px',
																					float: 'left',
																					height: 'auto',
																				}}
																				align="left"
																			/>}

																	</div>
																)}
															{logoType == '3' &&
																includeLogo == false && (
																	<div>
																		{/* <img
																			src={
																				imageflag == false ?
																					pictureForUpload ||
																					getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																			}
																			style={{
																				width: '150px',
																				float: 'left',
																				height: 'auto',
																			}}
																			align="left"
																		/> */}

																	</div>
																)}
														</div>
														<div
															id="square"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															{logoType == '1' &&
																includeLogo == true && (
																	<div>
																		{PictureName != null ?
																			<img
																				src={
																					imageflag == false ?
																						pictureForUpload ||
																						getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																				}
																				style={{
																					width: '160px',
																					height: 'auto',
																					float: 'middle',
																				}}
																				align="middle"
																			/> : <img
																				src={
																					Logo
																				}
																				style={{
																					width: '160px',
																					height: 'auto',
																					float: 'middle',
																				}}
																				align="middle"
																			/>}

																	</div>
																)}
															{logoType == '1' &&
																includeLogo == false && (
																	<div>
																		{/* <img
																			src={
																				imageflag == false ?
																					pictureForUpload ||
																					getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																			}
																			style={{
																				width: '160px',
																				height: 'auto',
																				float: 'middle',
																			}}
																			align="middle"
																		/> */}

																	</div>
																)}
														</div>
													</span>
												</p>
											</div>
											<div id="titleright">
												{showHeader == true &&
													titleAlignment == 'Right' && (
														<div
															class="practicePrintName practiceNameSquare "
															id="table-rowfooter"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															<p
																align="right"
																style={{
																	fontWeight: 'bold',
																	fontSize: '14px',
																	clear: 'both',
																	width: '100%',
																}}
															>
																Doctor Name<br />
																Qualification<br />
																Specialization.
															</p>
														</div>
													)}
											</div>
											<div id="titleleft">
												{showHeader == true &&
													titleAlignment == 'Left' && (
														<div
															class="practicePrintName practiceNameSquare "
															id="table-rowfooter"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															<p
																align="left"
																style={{
																	fontWeight: 'bold',
																	fontSize: '14px',
																	clear: 'both',
																	width: '100%',
																}}
															>
																Doctor Name<br />
																Qualification<br />
																Specialization.
															</p>
														</div>
													)}
											</div>
											<div id="titlecenter">
												{showHeader == true &&
													titleAlignment == 'Center' && (
														<div
															class="practicePrintName practiceNameSquare "
															id="table-rowfooter"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															<p
																align="center"
																style={{
																	fontWeight: 'bold',
																	fontSize: '14px',
																	clear: 'both',
																	width: '100%',
																}}
															>
																Doctor Name<br />
																Qualification<br />
																Specialization.
															</p>
														</div>
													)}
											</div>
										</td>
									</tr>
									<tr style={{ display: "flex", justifyContent: "space-between", width: "635px" }}>
										{showHeader == true && (
											<td

												style={{ textAlign: 'left', display: !showEditDiv ? '' : 'none' }}
											>
												<span
													style={{
														width: '100%',
														float: 'left',
														textAlign: 'left!important',
													}}
												>
													{printOutDoctorHeaderLeftText}
												</span>
											</td>
										)}

										{showHeader == true && printOutDoctorHeaderRightText.length > 0 && (
											<td

												style={{ textAlign: 'right', display: !showEditDiv ? '' : 'none' }}
											>
												<span
													style={{
														width: '100%',
														float: 'left',
														textAlign: 'right!important',
													}}
												>
													{printOutDoctorHeaderRightText}
												</span>
											</td>
										)}
									</tr>

									<tr style={{ display: 'table-row' }}>
										<td colspan="3">
											<hr color="#142536" />
										</td>
									</tr>
									{showHeader == true && (
										<tr style={{ display: !showEditDiv ? '' : 'none' }}>
											<td
												width="50%"
												align="left"
												style={{ fontSize: '12px', textAlign: 'left' }}
											>
												<span style={{ textAlign: 'left!important' }}> Patient Name</span>
												<br />
												<span style={{ textAlign: 'left!important' }}> Patient MR No</span>{' '}
												<br />
												<span style={{ textAlign: 'left!important' }}> Patient Age </span>
												<br />
											</td>
											<td />

											<td width="50%" align="right" style={{ fontSize: '12px' }}>
												<span style={{ textAlign: 'right!important', float: 'right' }}>
													Mobile No
												</span>
												<br />
												<span style={{ textAlign: 'right!important', float: 'right' }}>
													Date
												</span>
												<br />
												<span style={{ textAlign: 'right!important', float: 'right' }}>
													Gender
												</span>
												<br />
											</td>
										</tr>
									)}

									<tr>
										<td colspan="3" />
									</tr>
									<tr>
										<td colspan="3" style={{ fontWeight: '600' }}>
											{showRxLabel == true && (
												<div align="left" style={{ display: !showEditDiv ? '' : 'none' }}>
													Rx
												</div>
											)}

											<table width="100%">
												<thead
													style={{
														background: 'none',
														height: '30px',
														fontSize: '0.9em',
														color: '#393939',
													}}
												>
													<tr style={{ borderBottom: '2px solid black' }}>
														<th
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontSize: '600',
															}}
														/>
														<th
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontSize: '600',
															}}
														/>
														<th
															style={{
																borderBottom: '2px solid black',
																textAlign: 'right',
																fontSize: '600',
																paddingRight: '10px',
															}}
														/>
													</tr>

													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter" />
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter" />
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter" />
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
												</thead>
											</table>
										</td>
									</tr>

									<tr>
										<td colspan="3">
											<hr color="#142536" />
										</td>
									</tr>
									<tr>
										<td colspan="3">
											<div align="justify" class="practiceFootBtm">
												<div style={{ float: 'left', fontSize: '14px' }}>
													{doctorPrescriptionData.printOutHospitalFooterLeftSignature}
												</div>
												<div style={{ textAlign: 'right', fontSize: '14px' }}>
													{doctorPrescriptionData.printOutHospitalFooterRightSignature}
												</div>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="3">
											<div align="justify" class="practiceFootBtm">
												<div style={{ textAlign: 'center', fontSize: '14px' }}>
													{doctorPrescriptionData.printOutHospitalFooterFullWidthContent}
												</div>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="3">
											<div align="justify" class="practiceFootBtm">
												<div style={{ float: 'left' }} />
												<div id="footright">
													{' '}
													{showFooter == true &&
														footerAlignment == 'Right' && (
															<div
																style={{
																	textAlign: 'Right',
																	display: !showEditDiv ? '' : 'none',
																}}
															>
																{printOutDoctorFooterFullWidthContent}
															</div>
														)}
												</div>
												<div id="footleft">
													{showFooter == true &&
														footerAlignment == 'Left' && (
															<div
																style={{
																	textAlign: 'Left',
																	display: !showEditDiv ? '' : 'none',
																}}
															>
																{printOutDoctorFooterFullWidthContent}
															</div>
														)}
												</div>
												<div id="footcenter">
													{showFooter == true &&
														footerAlignment == 'Center' && (
															<div
																style={{
																	textAlign: 'Center',
																	display: !showEditDiv ? '' : 'none',
																}}
															>
																{printOutDoctorFooterFullWidthContent}
															</div>
														)}
												</div>
											</div>
										</td>
									</tr>

									<tr style={{ borderBottom: '.5px solid black' }}>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'right',
												fontSize: '600',
												paddingRight: '10px',
											}}
										/>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export default DoctorPrescription;
